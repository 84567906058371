export default [
     'abanoz',
     'abilir',
     'absürt',
     'acele',
     'aceleci',
     'aceleyle',
     'açgözlü',
     'açgözlülükle',
     'açı',
     'açığa',
     'açık',
     'açıkladı',
     'açıklamak',
     'açıklanması',
     'açıklık',
     'acil',
     'açıları',
     'acılı',
     'açılı',
     'acılık',
     'açılış',
     'aciliyet',
     'açılmak',
     'açılmamış',
     'acımasız',
     'acımasızlık',
     'açısal',
     'âciz',
     'açlık',
     'açlıktan',
     'açma',
     'açmak',
     'açtırmak',
     'adaçayı',
     'adalet',
     'adamak',
     'adanmış',
     'adayan',
     'adil',
     'adım',
     'adlandırma',
     'adsız',
     'aerobik',
     'afacan',
     'afallatmak',
     'afet',
     'affedilebilir',
     'affetmek',
     'afiş',
     'afl',
     'afyon',
     'ağaç',
     'ağaçlandırmak',
     'ağartmak',
     'ağıla',
     'ağırlık',
     'ağızdan',
     'ağızlık',
     'ağlama',
     'ağlamaklı',
     'agnostik',
     'agrega',
     'ağrıyan',
     'ağustos',
     'ağzı',
     'ahenkleştiren',
     'ahır',
     'ahize',
     'ahlak',
     'ahlaki',
     'ahlaksız',
     'ahlaksızca',
     'ahlaksızlık',
     'ahmak',
     'ahtapot',
     'ajan',
     'ajans',
     'ajur',
     'akademisyen',
     'akciğer',
     'akıllı',
     'akım',
     'akın',
     'akıncı',
     'akış',
     'akraba',
     'akrabalık',
     'akrabası',
     'akrep',
     'akrobat',
     'aksak',
     'aksaklık',
     'aksan',
     'aksilik',
     'aksiyon',
     'aktar',
     'aktif',
     'aktivatör',
     'aktivite',
     'aktivizm',
     'aktris',
     'akut',
     'alabalık',
     'alabora',
     'alacakaranlık',
     'alacaklı',
     'alâmet',
     'alanı',
     'alarak',
     'alay',
     'alaycı',
     'albatros',
     'albüm',
     'alçakça',
     'alçakgönüllülük',
     'alçakgönüllülükle',
     'alçaklık',
     'alçı',
     'aldatıcı',
     'aldatma',
     'aldatmaca',
     'aldırmamak',
     'alem',
     'alev',
     'alevlendirmek',
     'alevler',
     'alfabe',
     'algılama',
     'algoritması',
     'alıcı',
     'alıkoy',
     'alıkoyma',
     'alıngan',
     'alıntı',
     'alışılmış',
     'alıştırılmış',
     'alıştırma',
     'alıştırmak',
     'alışveriş',
     'alkali',
     'alkış',
     'alkışlamak',
     'alkışlar',
     'alma',
     'almak',
     'almanak',
     'alt',
     'altbilgi',
     'alt-grup',
     'altıgen',
     'altıncı',
     'altmışıncı',
     'altmışlı',
     'altyazı',
     'alüminyum',
     'amaç',
     'ambargo',
     'ambiyans',
     'amblem',
     'ambulans',
     'ameliyat',
     'amniyotik',
     'amonyak',
     'amonyum',
     'amper',
     'ampirik',
     'amplifikatör',
     'ampul',
     'amut',
     'anaerobik',
     'anafor',
     'anagramını',
     'anahat',
     'analık',
     'anatomi',
     'anatomici',
     'andıracak',
     'anemi',
     'anemik',
     'anevrizması',
     'ani',
     'aniden',
     'animasyon',
     'animatör',
     'anımsatan',
     'anıt',
     'anız',
     'anket',
     'anlamak',
     'anlamlı',
     'anlamsız',
     'anlaşma',
     'anlaşmazlık',
     'anlatılmamış',
     'anlayıcı',
     'anne',
     'anormal',
     'antarktik',
     'antenleri',
     'antiasit',
     'antik',
     'antikalar',
     'antikor',
     'antilop',
     'antiseptik',
     'antitoksik',
     'antitröst',
     'antivirüs',
     'antoloji',
     'antre',
     'aort',
     'apandis',
     'apresi',
     'aptal',
     'aptallık',
     'araba',
     'arabası',
     'araç',
     'araçsallaştırılıyor',
     'arada',
     'araf',
     'aralık',
     'aranan',
     'arasında',
     'araştır',
     'araştırma',
     'arazi',
     'ardıç',
     'argo',
     'arındırmak',
     'arınma',
     'arıtılmamış',
     'arızalı',
     'arızamsın',
     'arkadaş',
     'arma',
     'armağan',
     'armonika',
     'aromalı',
     'arpa',
     'arsa',
     'arsız',
     'artı',
     'artık',
     'artırılmış',
     'arz',
     'asa',
     'asabi',
     'aşağı',
     'aşağılamak',
     'aşağılayıcı',
     'aşan',
     'asansör',
     'aseton',
     'asfalt',
     'asfaltsız',
     'asi',
     'aşikar',
     'aşılama',
     'aşıldı',
     'aşılı',
     'asılsız',
     'aşım',
     'aşındırıcı',
     'aşınma',
     'aşiri',
     'aşırı',
     'asit',
     'askıya',
     'asla',
     'aslan',
     'aşmak',
     'ast',
     'astar',
     'astroloji',
     'astronomi',
     'astronot',
     'aşurelik',
     'atacılık',
     'atama',
     'atamak',
     'atanan',
     'ateş',
     'ateşkes',
     'ateşleme',
     'atıcı',
     'atılgan',
     'atılım',
     'atıp',
     'atipik',
     'atlama',
     'atlantik',
     'atlatmak',
     'atlıkarınca',
     'atma',
     'atmak',
     'atomaltı',
     'atomu',
     'atrofi',
     'attı',
     'avans',
     'avare',
     'avcı',
     'avcılık',
     'avlu',
     'avuç',
     'avut',
     'ay-',
     'ayakkabı',
     'ayakkabıcı',
     'ayakkabılar',
     'ayakkabılı',
     'ayaklanma',
     'ayaksız',
     'ayakta',
     'ayar',
     'ayaz',
     'aydınlanma',
     'aydınlanmak',
     'aydınlatmak',
     'aydınlatmalı',
     'aydınlattığı',
     'ayet',
     'aygır',
     'ayıklama',
     'ayırın',
     'ayırmak',
     'ayırt',
     'aylık',
     'ayna',
     'aynası',
     'aynen',
     'aynı',
     'ayrıldı',
     'ayrılık',
     'ayrılma',
     'ayrılmak',
     'ayrılmış',
     'ayrıntılı',
     'ayrıştırmak',
     'aytaşı',
     'ayy',
     'ayyaş',
     'az',
     'azaltmak',
     'azar',
     'azarlama',
     'azarlardım',
     'azat',
     'aziz',
     'baba',
     'babalar',
     'babalık',
     'babun',
     'bacaklı',
     'bagaj',
     'bağcık',
     'baget',
     'bağır',
     'bağırıyor',
     'bağırsaklar',
     'bağış',
     'bağışlanan',
     'bağışlayan',
     'bağlam',
     'bağlama',
     'bağlanmış',
     'bağlantılı',
     'bağlantısız',
     'bağlı',
     'bağlılıkla',
     'bahane',
     'bahar',
     'bahçe',
     'bahis',
     'bakan',
     'bakici',
     'bakıcısı',
     'bakılabilir',
     'bakınarak',
     'bakır',
     'bakış',
     'bakışta',
     'baklagildir',
     'bakmak',
     'bakteri',
     'baldıran',
     'balenli',
     'balıklama',
     'balkon',
     'balta',
     'balzamik',
     'bambu',
     'bankacı',
     'bankacılık',
     'bankamatik',
     'banknot',
     'bantsız',
     'banyo',
     'barbekü',
     'bardak',
     'baret',
     'barikat',
     'barınak',
     'barışçı',
     'bariyer',
     'barkod',
     'barmen',
     'barometre',
     'baş',
     'başarısız',
     'başhemşire',
     'başıboş',
     'başım',
     'basın',
     'basit',
     'basitçe',
     'basitleştirmek',
     'başkent',
     'baskı',
     'baskın',
     'başlamak',
     'başlangıç',
     'başlangıcı',
     'başlangıçlı',
     'başlatmak',
     'başlayacak',
     'baslığı',
     'başlığı',
     'başlık',
     'basmış',
     'başörtüsü',
     'başparmak',
     'başsız',
     'bastırılmış',
     'bastırmak',
     'bataklık',
     'bataklıktan',
     'batırılma',
     'batma',
     'battı',
     'bavul',
     'bayat',
     'bayi',
     'bayır',
     'baykuş',
     'bayram',
     'bazuka',
     'becerememek',
     'becerikli',
     'beceriklilik',
     'beceriksizce',
     'beğeni',
     'beğenmeme',
     'beğenmemek',
     'bekâr',
     'bekârlık',
     'bekçi',
     'bekliyor',
     'belâ',
     'belagat',
     'belediye',
     'beleş',
     'belge',
     'belirgin',
     'belirlilik',
     'belirsiz',
     'belirsizlik',
     'benekler',
     'benekli',
     'benzerlik',
     'benzersiz',
     'benzetme',
     'beraberindekileri',
     'berber',
     'berrak',
     'bertaraf',
     'beş',
     'beşik',
     'besin',
     'beşinci',
     'beslemek',
     'beşli',
     'besteci',
     'bey',
     'beyan',
     'beyanname',
     'beyazlaşma',
     'beyefendi',
     'beyler',
     'bıçaklamak',
     'biçen',
     'biçimdeğişimi',
     'biçme',
     'bigudi',
     'bıldırcın',
     'bildirge',
     'bilek',
     'bilerek',
     'bileşen',
     'bileşik',
     'bilgelik',
     'bilgisayar',
     'bilgisiz',
     'bilim',
     'bilinçsiz',
     'bilinen',
     'bilinmeyen',
     'bilinmezlik',
     'biliş',
     'bilişsel',
     'bilmeden',
     'bin',
     'bindirme',
     'binme',
     'binmek',
     'bip',
     'bırakmak',
     'biraz',
     'birçok',
     'birden',
     'birikim',
     'birim',
     'birincil',
     'birleşik',
     'birleşme',
     'birleştiricidir',
     'birleştirme',
     'birleştirmek',
     'birlik',
     'bisikletçi',
     'bitiricilik',
     'bitiriş',
     'bitirme',
     'bitki',
     'bitkin',
     'bitkisel',
     'bitmeyen',
     'bitmiş',
     'bitti',
     'bıyık',
     'bla',
     'blöf',
     'blöfçü',
     'bluz',
     'bobin',
     'böbürlenen',
     'böbürlenerek',
     'böbürlenme',
     'boğa',
     'boğaz',
     'boğma',
     'boğmaca',
     'boğmak',
     'boğucu',
     'boğukluk',
     'boğulma',
     'boğulur',
     'böğürme',
     'boğuşma',
     'boks',
     'boksör',
     'bol',
     'bolca',
     'bölge',
     'bölgeleme',
     'bölgesi',
     'bolluk',
     'bölme',
     'bölmek',
     'bölücü',
     'bölücüler',
     'bölüm',
     'bölümlendirmesi',
     'bölünebilir',
     'bölünme',
     'bölünmemiş',
     'bölünmesi',
     'bölünmüş',
     'bono',
     'boraks',
     'borazanı',
     'borçlanma',
     'borçlu',
     'borda',
     'bordo',
     'börek',
     'borular',
     'boş',
     'boşaltıcı',
     'boşaltılabilir',
     'boşaltım',
     'boşaltma-',
     'boşaltmak',
     'boşaltmış',
     'boşboğazlık',
     'boşluğu',
     'boşluk',
     'botanik',
     'botanikçi',
     'boyama',
     'boyanabilen',
     'boyanmamış',
     'böyle',
     'böylece',
     'boylu',
     'boynuz',
     'boyunduruğumuz',
     'boyunduruk',
     'boyut',
     'boyutlandırma',
     'bozma',
     'bozmak',
     'bozukluğu',
     'bozulmadan',
     'bozulmak',
     'bozulmalar',
     'bozulmamış',
     'bozulmuş',
     'bronşiyal',
     'bronz',
     'bronzlaşma',
     'broşür',
     'brüksel',
     'bucak',
     'bücür',
     'budaklı',
     'budala',
     'budama',
     'budist',
     'budizm',
     'buğday',
     'buhar',
     'buharlaşmak',
     'buharlaştırıcı',
     'buklelenme',
     'bükülebilir',
     'bükülmemiş',
     'bükülmüş',
     'büküm',
     'bulanık',
     'bulanıklık',
     'bulantı',
     'buldozer',
     'bulmaca',
     'bulmak',
     'bulunmak',
     'bulut',
     'bulutlu',
     'bulutsu',
     'bulvar',
     'bunlar',
     'burkulma',
     'burmak',
     'büro',
     'burs',
     'burun',
     'bürünmüş',
     'buruşuk',
     'büst',
     'bütçe',
     'bütün',
     'büyücü',
     'büyüdü',
     'büyüğü',
     'büyük',
     'büyükanne',
     'büyükbaba',
     'büyükçe',
     'büyüklüğü',
     'büyüklük',
     'büyülü',
     'büyümek',
     'büyümüş',
     'büyüteci',
     'büyütme',
     'büyütülmüş',
     'büyüyen',
     'buz',
     'buzdolabı',
     'buzlanma',
     'buzlu',
     'büzme',
     'buzul',
     'büzülerek',
     'çaba',
     'çabalama',
     'çabası',
     'çabukluk',
     'cadaloz',
     'çağırma',
     'çağlayan',
     'çak',
     'çakal',
     'çakı',
     'çakıl',
     'çakıllı',
     'çakmaktaşı',
     'çaldı',
     'çalı',
     'çalılar',
     'çalılık',
     'çalım',
     'çalınması',
     'çalışabilen',
     'çalışan',
     'çalışkan',
     'çalışkanlık',
     'çalıştırmak',
     'çalkantılı',
     'cam',
     'çamaşır',
     'çamaşırlar',
     'çamur',
     'çamurluk',
     'çamuru',
     'cana',
     'canavarca',
     'cani',
     'canlandırıcı',
     'canlandırılabilecek',
     'canlandırılması',
     'canlandırma',
     'canlandırmak',
     'canlanma',
     'canlı',
     'canlılık',
     'çanta',
     'çantası',
     'çap',
     'çapa',
     'capcanlı',
     'çapraşık',
     'çapraz',
     'çare',
     'çaresiz',
     'çarpıcı',
     'çarpık',
     'çarpışma',
     'çarpışmak',
     'çarpıştılar',
     'çarpitilmis',
     'çarpıtmak',
     'çarpmak',
     'casusluk',
     'çatırdayan',
     'çatışma',
     'çatlak',
     'çatlamış',
     'caz',
     'cazibesi',
     'cazip',
     'cebelleşme',
     'cebir',
     'çedar',
     'çek',
     'çekçek',
     'ceket',
     'çekici',
     'çekici',
     'çekiliş',
     'çekilmeye',
     'çekimser',
     'çekinme',
     'çekirdek',
     'çekirdekleri',
     'çekiş',
     'çekişli',
     'çekişme',
     'çekişmeler',
     'çelenk',
     'çelimsiz',
     'çelmek',
     'cenazeci',
     'çeneler',
     'çeneli',
     'çenesiz',
     'cephanelik',
     'çerçeve',
     'çerçeveleme',
     'çerçeveli',
     'çerçevesiz',
     'çerez',
     'cerrahi',
     'çeşitli',
     'çeşitlilik',
     'çeşme',
     'çeşni',
     'cesur',
     'cesurca',
     'cevap',
     'cevaplama',
     'çevik',
     'çevikçe',
     'çeviklik',
     'çevirmek',
     'ceviz',
     'çevreleme',
     'çevrilmemiş',
     'çeyiz',
     'ceza',
     'cezalandırabilir',
     'cezalandırıcı',
     'cezir',
     'çiçeği',
     'çiçek',
     'çiçekçi',
     'çiçeklenme',
     'ciddiyetine',
     'çiftleme',
     'çiftleşme',
     'çiftlik',
     'çığ',
     'çığlık',
     'çiğneme',
     'çiğnenebilir',
     'çiğneyici',
     'cihaz',
     'çıkarılabilir',
     'çıkarılmamış',
     'çıkarma',
     'çıkarmadan',
     'çıkarmak',
     'çıkartma',
     'çıkın',
     'çıkıntısı',
     'çıkış',
     'çıkmaz',
     'çıktı',
     'cilalama',
     'çile',
     'çılgın',
     'çılgınca',
     'çılgınlığı',
     'çılgınlık',
     'cılız',
     'çiller',
     'çilli',
     'ciltli',
     'çim',
     'cımbız',
     'çimdik',
     'çimen',
     'çimento',
     'cimri',
     'çınar',
     'cinayet',
     'çıngırtısından',
     'çınlama',
     'cinsiyet',
     'cinsli',
     'çıplak',
     'çıra',
     'çirkin',
     'çirkinleştirmek',
     'çırpıcı',
     'çırpma',
     'cırtlı',
     'çiseleyen',
     'çit',
     'çıtır',
     'cıvata',
     'cıvık',
     'cıvıldamak',
     'çizdi',
     'çizelgesi',
     'çizgisiz',
     'cızırtı',
     'cızırtılı',
     'çizmek',
     'çocukça',
     'çocukluk',
     'çocuksu',
     'çocuksuz',
     'çoğaltamaz',
     'çoğaltma',
     'coğrafya',
     'çoğu',
     'çoğul',
     'çoğunluk',
     'çok',
     'çökelmek',
     'çökertilmesi',
     'çokgen',
     'çokluk',
     'çökmüş',
     'çöküş',
     'çomak',
     'çömelme',
     'cömert',
     'çömez',
     'constabie',
     'çöp',
     'çöpçü',
     'copilotu',
     'çöplüğü',
     'çörek',
     'coşkulu',
     'çöz',
     'çözmek',
     'çözüldü',
     'çözülme',
     'çözülmek',
     'çözülmekte',
     'çözülmemiş',
     'çubuk',
     'cüce',
     'çükü',
     'çukurluğu',
     'cuma',
     'cumartesi',
     'cümleleme',
     'cüret',
     'cüretli',
     'çürük',
     'cürüm',
     'çürüme',
     'çürütme',
     'çürütmek',
     'çürütülebilir',
     'çürüyen',
     'dadı',
     'dağ',
     'dağılma',
     'dağınık',
     'dağınıklık',
     'dağıtacak',
     'dağıtmak',
     'dağıtmıyorlar',
     'dahi',
     'dahl',
     'daire',
     'dairesel',
     'dakik',
     'dal',
     'dalak',
     'dalan',
     'dalaş',
     'dalaşmak',
     'daldırma',
     'dalga',
     'dalgalanan',
     'dalgalanma',
     'dalgalı',
     'dalgın',
     'dalış',
     'dalkavukluk',
     'damat',
     'damgasız',
     'damızlık',
     'damla',
     'damlacık',
     'damlalık',
     'damlama',
     'damperli',
     'damping',
     'dan',
     'dangalak',
     'danışmak',
     'dans',
     'dar',
     'daracık',
     'darağacı',
     'darıldın',
     'darlığı',
     'darwinizm',
     'dasitanî',
     'dava',
     'davetsiz',
     'davul',
     'dayanak',
     'dayanıklı',
     'dayanıklılık',
     'dayanır',
     'dazlak',
     'dede',
     'dedektif',
     'dedektörü',
     'dedikodu',
     'dedim',
     'defans',
     'deflasyon',
     'deflatörü',
     'deflektör',
     'defne',
     'değeri',
     'değerlendirici',
     'değerlendirme',
     'değerlendirmek',
     'değersiz',
     'değişen',
     'değişiklik',
     'değişken',
     'değişkenliklidir',
     'değişmeden',
     'değişmekteydi',
     'değişmemiş',
     'değişmez',
     'değiştirici',
     'değiştirilmemiş',
     'değiştirilmiş',
     'değiştirme',
     'değiştirmek',
     'dehşet',
     'dekan',
     'dekatlon',
     'dekoratör',
     'delice',
     'delici',
     'delikanlı',
     'deliksiz',
     'delilik',
     'deliller',
     'delinmiş',
     'deliryum',
     'delmek',
     'deme',
     'demet',
     'demir',
     'demiryolu',
     'demleme',
     'demokrasi',
     'demokrat',
     'demontajı',
     'denatüre',
     'deneme',
     'denenmemiş',
     'denenmiş',
     'denetlemek',
     'denetlenmemiş',
     'deneyin',
     'denge',
     'dengeleme',
     'dengesiz',
     'dengesizlik',
     'deniz',
     'denizaltı',
     'denizayısı',
     'denizcilik',
     'denize',
     'denizyıldızı',
     'denklem',
     'depo',
     'depolama',
     'depolanabilir',
     'deponun',
     'deprem',
     'dere',
     'derebeyi',
     'derecelendirilir',
     'derecelendirilmemiş',
     'derecelendirme',
     'derecesi',
     'dereotu',
     'dergi',
     'derhal',
     'derin',
     'derinden',
     'derinleştirmek',
     'derinliği',
     'derinlik',
     'derisiz',
     'derleme',
     'dertli',
     'deşarj',
     'desibel',
     'deşifre',
     'desimetre',
     'deşmek',
     'destan',
     'destek',
     'desteklemek',
     'desteklenen',
     'detay',
     'deterjan',
     'detoksifiye',
     'dev',
     'devalüasyon',
     'devamsızlık',
     'devasa',
     'devirmek',
     'devredilebilir',
     'devrik',
     'devrilme',
     'deyim',
     'dezenfektanı',
     'dezenfekte',
     'difüzör',
     'difüzyon',
     'diğer',
     'dihidrat',
     'dik',
     'dikdörtgen',
     'diken',
     'dikenli',
     'dikey',
     'dikiş',
     'dikiz',
     'dikkat',
     'dikkatle',
     'dikkatsiz',
     'diktatör',
     'dikte',
     'dil',
     'dilatasyon',
     'dilbilimci',
     'dilek',
     'dilekçe',
     'dilenmek',
     'dilimleme',
     'dilimlenebilir',
     'dilimlenmemiş',
     'dilimlenmiş',
     'dinamik',
     'dinamit',
     'dinç',
     'dinlediğiniz',
     'dinsiz',
     'dioksit',
     'dipnot',
     'direksiyon',
     'direktif',
     'direnerek',
     'direnmeye',
     'dirileştirici',
     'dirsek',
     'diş',
     'dış',
     'dışa',
     'disi',
     'dişi',
     'dışkı',
     'dışlama',
     'dışlamak',
     'dışlanmış',
     'disleksi',
     'disleksik',
     'dişli',
     'dıştan',
     'divan',
     'diyabet',
     'diyafram',
     'diyagram',
     'diz',
     'dizi',
     'dizin',
     'doğa',
     'doğaçlama',
     'doğal',
     'doğru',
     'doğrulayın',
     'doğrultmak',
     'doğrultulmuş',
     'doğruluk',
     'doğuş',
     'doğuya',
     'döken',
     'dökme',
     'dökmeyen',
     'doksanıncı',
     'doktrin',
     'doku',
     'dökülen',
     'dökülme',
     'dokuma',
     'dokunarak',
     'dokunma',
     'dokunmamış',
     'döküntü',
     'dokunulmamış',
     'dokunulmazlık',
     'dokuzuncu',
     'dolambaçlı',
     'dolandırdığınız',
     'dolar',
     'dolaşık',
     'dolaşım',
     'dolaştılar',
     'dolayısıyla',
     'doldurma',
     'doldurmak',
     'doldurulmamış',
     'dolgu',
     'dolgulu',
     'dolgun',
     'dolması',
     'dolmuş',
     'doluluk',
     'dolumu',
     'doluyor',
     'domino',
     'donanım',
     'donanma',
     'dondurucu',
     'dondurulabilir',
     'dondurulmuş',
     'dönek',
     'döner',
     'döngü',
     'donma',
     'donmamış',
     'dönme',
     'donukluk',
     'dönümlük',
     'dönüş',
     'dönüşümü',
     'dörde',
     'dorse',
     'dörtlü',
     'dörtte',
     'doruk',
     'döş',
     'döşeme',
     'dostça',
     'dostum',
     'dosyalama',
     'dosyalanmamış',
     'dövme',
     'dövüş',
     'doymuş',
     'doz',
     'dozaj',
     'dramatik',
     'drenaj',
     'dua',
     'dublajlı',
     'dubleks',
     'dublör',
     'dudak',
     'dük',
     'dul',
     'duman',
     'dumanlı',
     'dumansız',
     'dümen',
     'dümenci',
     'dün',
     'dünyalı',
     'dünyevi',
     'düpedüz',
     'dur',
     'durağan',
     'duraklaması',
     'duraksama',
     'dürbün',
     'durdu',
     'durdurucu',
     'durdurulabilir',
     'durduruluyor',
     'durgun',
     'durgunluk',
     'durması',
     'dürtme',
     'dürtü',
     'durulama',
     'durum',
     'durumu',
     'duruş',
     'dürüst',
     'duş',
     'düşes',
     'düşman',
     'düşmanlık',
     'düşündürmek',
     'düşünmek',
     'düşüş',
     'dut',
     'duvar',
     'duyarlı',
     'duygu',
     'duygusuz',
     'duymayan',
     'duyulmamış',
     'duyumsal',
     'düzeltici',
     'düzeltilmemiş',
     'düzenbaz',
     'düzenlemek',
     'düzenlenmemiş',
     'düzenleyen',
     'düzenli',
     'düzenlilik',
     'düzensiz',
     'düzeylendirilmemiş',
     'düzleştirmek',
     'ebru',
     'eczacı',
     'edebikelâm',
     'edepsiz',
     'edicisi',
     'editör',
     'eflatun',
     'efsane',
     'egemenliği',
     'eğilmeden',
     'eğilmek',
     'eğimli',
     'eğirme',
     'eğitim',
     'eğitimci',
     'eğitimli',
     'eğitimsiz',
     'eğlence',
     'eğlenceli',
     'eğlendirmek',
     'eğlenmiş',
     'egotizm',
     'eğreti',
     'eğrilik',
     'eğrisi',
     'egzersiz',
     'egzosuydu',
     'egzoz',
     'ehliyetsiz',
     'e-kart',
     'eki',
     'ekilmemiş',
     'ekim',
     'ekinoks',
     'ekipman',
     'e-kitap',
     'ekleme',
     'eklerin',
     'eko',
     'ekoloji',
     'ekolojist',
     'ekonomi',
     'ekonomik',
     'ekosfer',
     'ekosistem',
     'ekran',
     'ekranda',
     'eksantrik',
     'eksen',
     'ekşilik',
     'ekskavatör',
     'ekstralar',
     'ekstrüzyon',
     'ekvator',
     'elastik',
     'elbise',
     'elbisesi',
     'elçilik',
     'elçisi',
     'elde',
     'eldiven',
     'eldivensiz',
     'ele',
     'eleme',
     'elemek',
     'elfler',
     'elini',
     'elips',
     'eliptik',
     'elişi',
     'elitizm',
     'elli',
     'ellinci',
     'elma',
     'elverişli',
     'elverişsizlik',
     'elyaf',
     'emanet',
     'emanetçi',
     'emaye',
     'embesil',
     'embolizm',
     'emekçi',
     'emekleme',
     'emekli',
     'emeklilik',
     'emin',
     'emisyon',
     'emlak',
     'emme',
     'empati',
     'emperyal',
     'emtia',
     'emzik',
     'endişelenmek',
     'endişeli',
     'endokrin',
     'endorfin',
     'enerji',
     'enerjik',
     'enfiye',
     'engel',
     'engelledi',
     'engellemek',
     'engelleyen',
     'engelsiz',
     'engerek',
     'enkazının',
     'enlem',
     'ense',
     'enzim',
     'epik',
     'epilepsi',
     'epileptik',
     'e-posta',
     'erdemli',
     'ergonomik',
     'eril',
     'eritme',
     'erkekçe',
     'erkeklik',
     'erkeksi',
     'erken',
     'eroinman',
     'erteleme',
     'ertelenmiş',
     'esansiyel',
     'eşarp',
     'eşik',
     'esintili',
     'esir',
     'eşit',
     'eşitlik',
     'eşitsiz',
     'eşitsizliği',
     'eski',
     'eskrim',
     'eşlenmemiş',
     'eşleşmemiş',
     'eşleştirici',
     'eşleştirme',
     'esmer',
     'esnek',
     'esrar',
     'esrarengiz',
     'eşsiz',
     'estrojen',
     'etanol',
     'etek',
     'eter',
     'etik',
     'etiket',
     'etiketsiz',
     'etkilenen',
     'etkilenmiş',
     'etkileri',
     'etkileyen',
     'etkili',
     'etkinleştirme',
     'etkinleştirmek',
     'etkisiz',
     'etli',
     'etobur',
     'etrafında',
     'evcillik',
     'evet',
     'evlenmek',
     'evlenmemiş',
     'evli',
     'evreleme',
     'evren',
     'evrensel',
     'evrim',
     'eylemci',
     'eylül',
     'ezen',
     'ezerek',
     'ezilebilir',
     'ezilen',
     'ezilmemiş',
     'ezilmiş',
     'ezme',
     'fahiş',
     'fakih',
     'fakir',
     'faks',
     'faktörü',
     'fakülte',
     'fanatik',
     'fantastik',
     'fantezi',
     'fantom',
     'far',
     'fare',
     'farkedilmeden',
     'farkında',
     'faşizm',
     'favori',
     'fayans',
     'faydalı',
     'faydasızca',
     'fazlalık',
     'fazlasıyla',
     'feci',
     'felâket',
     'felç',
     'felçler',
     'feminizm',
     'fena',
     'fenalık',
     'fener',
     'feragat',
     'feribot',
     'fermuarlar',
     'fervour',
     'fesat',
     'fesleğen',
     'fevkalade',
     'fıçı',
     'fidan',
     'figür',
     'fıkra',
     'fil',
     'fildişi',
     'filiz',
     'filtre',
     'finali',
     'finanse',
     'fındık',
     'finişer',
     'firar',
     'fırça',
     'fırfır',
     'fırın',
     'fırlatan',
     'fırlatmak',
     'fırlattı',
     'fırtına',
     'fiş',
     'fışkırma',
     'fışkırtma',
     'fitil',
     'flaması',
     'flaneller',
     'flaşör',
     'flört',
     'fobi',
     'folik',
     'folikül',
     'folyo',
     'fon',
     'fondü',
     'fonetik',
     'fonlanmayan',
     'fortune',
     'fosfat',
     'fosil',
     'fosseptik',
     'fotoğraf',
     'fotokopi',
     'fragmanı',
     'fraksiyonu',
     'fransızca',
     'frizbi',
     'früktoz',
     'fuaye',
     'fukara',
     'fular',
     'füme',
     'fundalık',
     'futbol',
     'gaf',
     'gafil',
     'galeri',
     'galon',
     'galvaniz',
     'gama',
     'gamze',
     'gangster',
     'ganimet',
     'ganimetleri',
     'garaj',
     'gardiyan',
     'garez',
     'gargara',
     'garip',
     'garnitür',
     'gasp',
     'gayda',
     'gayret',
     'gayretlendirmek',
     'gaz',
     'gazap',
     'gazyağı',
     'gebelik',
     'geç',
     'geçebiliyor',
     'gecekondu',
     'geçen',
     'geçerli',
     'geçersiz',
     'gecikme',
     'gecikmeli',
     'gecikmiş',
     'geçirdi',
     'geçirmek',
     'geçit',
     'geko',
     'gel',
     'gelenek',
     'gelgit',
     'gelin',
     'gelincik',
     'gelir',
     'gelişen',
     'gelişigüzel',
     'gelişmek',
     'geliştirmek',
     'gelmesi',
     'gemi',
     'gemisi',
     'gemiye',
     'genç',
     'genel',
     'genetik',
     'geniş',
     'genişleme',
     'genişletilmiş',
     'genişletmek',
     'genişletmektir',
     'genişlik',
     'geniz',
     'geometri',
     'geometrik',
     'gerçek',
     'gerçekleştirilebilir',
     'gerçeküstü',
     'gereksiz',
     'gerektiğini',
     'gerektirir',
     'gergin',
     'gerginlik',
     'geriatrik',
     'gerileme',
     'geriye',
     'getirdi',
     'getirme',
     'getirmek',
     'gevezelik',
     'gevrek',
     'gevşeklik',
     'gevşemek',
     'geyiğinin',
     'geyik',
     'gezi',
     'gezinme',
     'gezmeye',
     'gıcırtı',
     'gıda',
     'giden',
     'gider',
     'gidermek',
     'gidip',
     'gidiyor',
     'gidon',
     'gigabayt',
     'girdap',
     'girerken',
     'girinti',
     'girişim',
     'gitmiş',
     'giydirmek',
     'giyim',
     'giysi',
     'gizlemek',
     'gizlenmek',
     'gizli',
     'gizlice',
     'gizlilik',
     'gladyatör',
     'glandüler',
     'glikoz',
     'glokom',
     'glüten',
     'göbek',
     'goblen',
     'göç',
     'gofret',
     'göğe',
     'göğüs',
     'gök',
     'gökyüzü',
     'göl',
     'golcü',
     'gölet',
     'gölge',
     'gölgeleme',
     'gölgeli',
     'gölgelik',
     'golsüz',
     'gömlek',
     'gönderildi',
     'gönderilmemiş',
     'gönderme',
     'göndermek',
     'gondol',
     'gönül',
     'görev',
     'görevden',
     'görevlendirecektir',
     'görevli',
     'görevlisi',
     'görkemli',
     'görünmeyen',
     'görüntü',
     'görüntüle',
     'görüntüleme',
     'görüntülenebilir',
     'görüntüleyici',
     'görünüm',
     'görünür',
     'görünüş',
     'gösteren',
     'gösteri',
     'gösterilen',
     'gösterişli',
     'göstermek',
     'göstermelik',
     'gotik',
     'gövde',
     'gözaltı',
     'gözardı',
     'gözcü',
     'gözenekli',
     'gözetim',
     'gözetledi',
     'gözetlemek',
     'gözlemci',
     'gözleme',
     'gözlemlemek',
     'gözlük',
     'gözüpek',
     'gradasyon',
     'gradyan',
     'grafik',
     'grafiktir',
     'granat',
     'granit',
     'granül',
     'greyder',
     'gri',
     'gruplandırılmış',
     'gübre',
     'güç',
     'güçlendirmek',
     'güçlü',
     'güçlük',
     'gücü',
     'güderi',
     'güdü',
     'güdük',
     'güdümsüz',
     'gülümsemek',
     'güm',
     'gümbürtü',
     'gümüş',
     'günah',
     'günahkar',
     'günahkarlık',
     'günahsız',
     'güncelleme',
     'gündem',
     'gündüz',
     'güneşlik',
     'günlük',
     'güreş',
     'guruldama',
     'gürültü',
     'gururlandım',
     'gururlu',
     'gut',
     'güveç',
     'güven',
     'güvenen',
     'güvenilir',
     'güvenli',
     'güvenlik',
     'güvensiz',
     'güvensizlik',
     'güvercin',
     'güverte',
     'güzel',
     'güzergah',
     'habercisidir',
     'habersiz',
     'hacimleri',
     'haciz',
     'hacklemek',
     'haçlı',
     'hadım',
     'hafif',
     'hain',
     'hainlik',
     'hak',
     'hakaret',
     'hakem',
     'hakemlik',
     'hakikat',
     'hakim',
     'haklı',
     'haklılık',
     'haksız',
     'haksızlık',
     'halk',
     'halkalı',
     'halojen',
     'halsiz',
     'halter',
     'hamak',
     'hamile',
     'hamsi',
     'hamur',
     'hamurumsu',
     'hançer',
     'hanedan',
     'hanımlar',
     'hantal',
     'hapis',
     'hapishane',
     'hapsedip',
     'hapşırık',
     'hapşırma',
     'hapsolmuş',
     'harap',
     'hararetle',
     'harbiyeli',
     'harç',
     'harcama',
     'harcanabilir',
     'harcayan',
     'hardal',
     'hareket',
     'harekete',
     'hareketli',
     'hareketlilik',
     'hareketsiz',
     'hariç',
     'harika',
     'harita',
     'harman',
     'harmonikler',
     'harp',
     'harpçı',
     'hasar',
     'hasarsız',
     'hasat',
     'haset',
     'haşhaş',
     'hasis',
     'haşlama',
     'haşlanmış',
     'haslığı',
     'hasret',
     'hassas',
     'hasta',
     'hat',
     'hata',
     'hatır',
     'hatırlama',
     'hatırlamak',
     'hatırlatma',
     'hatta',
     'hav',
     'hava',
     'havacı',
     'havacılık',
     'havada',
     'havadis',
     'havai',
     'havalandırma',
     'havalanmak',
     'havari',
     'havasız',
     'havlama',
     'havuç',
     'havyar',
     'havzası',
     'hayal',
     'hayalci',
     'hayali',
     'hayalimdeki',
     'hayat',
     'hayati',
     'hayatta',
     'haydut',
     'hayırlı',
     'haykıracak',
     'hayrete',
     'hayvan',
     'hayvancılık',
     'haziran',
     'hazırlamak',
     'hedef',
     'hediye',
     'helyum',
     'hem',
     'hemşirelik',
     'hendek',
     'hentbol',
     'hepçil',
     'herbisit',
     'hergele',
     'herkes',
     'hesap',
     'hesaplamak',
     'hevesle',
     'hevesli',
     'heybe',
     'heybetli',
     'heyecan',
     'heyecanlandırmak',
     'heyecansız',
     'heyelan',
     'heykel',
     'heykelcik',
     'heykeltraş',
     'hibe',
     'hibrid',
     'hidrant',
     'hidratasyon',
     'hidratlı',
     'hidrojen',
     'hidroksit',
     'hilal',
     'hile',
     'hilebaz',
     'hilesiz',
     'himayesi',
     'hımbıl',
     'hipnotik',
     'hipnotizmacı',
     'hipnoz',
     'hırgür',
     'hırka',
     'hırlamak',
     'hırpalanmış',
     'hırs',
     'hırsız',
     'hırsızlık',
     'hırslı',
     'hışırdatmak',
     'hisler',
     'hissedar',
     'hissetmek',
     'hissizce',
     'hız',
     'hizip',
     'hızla',
     'hızlandıracağını',
     'hızlandırılmış',
     'hızlandırmak',
     'hizmet',
     'hokkabaz',
     'hokkabazlık',
     'homurdanarak',
     'homurdanma',
     'homurtu',
     'hoppala',
     'hoppalık',
     'hor',
     'hörgüçlü',
     'horlama',
     'hoş',
     'hoşlanma',
     'hoşnutsuzluk',
     'hovarda',
     'hücum',
     'hukukçu',
     'hükümsüz',
     'hülyalı',
     'humourless',
     'humus',
     'hurda',
     'hurdacı',
     'hurdalık',
     'hurra',
     'huysuzluk',
     'huzursuz',
     'huzursuzluk',
     'iade',
     'içbükey',
     'içerikleri',
     'içilebilir',
     'içini',
     'içme',
     'içti',
     'iddia',
     'iddiası',
     'idealizm',
     'idealleştirmek',
     'ideoloji',
     'ifade',
     'iffet',
     'iffetli',
     'ifşa',
     'iftira',
     'iglo',
     'iğneleyici',
     'iğrenç',
     'iğrençlik',
     'ihanet',
     'ihlal',
     'ihlâl',
     'ihmal',
     'ihmalkar',
     'ihraç',
     'ihracatçı',
     'ihtişamı',
     'ikamet',
     'ikili',
     'ikinci',
     'ikincisi',
     'ikiyüzlü',
     'ikiyüzlülük',
     'ikizler',
     'iklim',
     'ikna',
     'ikramiye',
     'iksir',
     'iktidarsız',
     'iktidarsızlık',
     'iktisatçı',
     'ilahi',
     'ilahiyat',
     'ilçe',
     'ilçesi',
     'ilerde',
     'ileriye',
     'ilerleme',
     'iletişim',
     'ilgası',
     'ilgili',
     'ilgisiz',
     'iliği',
     'ılık',
     'ilişkilendirilebilir',
     'ilişkili',
     'ilişkisi',
     'ilkbahar',
     'ilkel',
     'iltica',
     'imleç',
     'immunise',
     'imparator',
     'imrenilen',
     'imrenme',
     'imza',
     'inanç',
     'inandırıcı',
     'inanılmaz',
     'inatçı',
     'ince',
     'inceleme',
     'inceleyen',
     'incelik',
     'incelikli',
     'inceltme',
     'incik',
     'indi',
     'indirgemek',
     'indirim',
     'indis',
     'inek',
     'inilti',
     'inine',
     'iniş',
     'inkar',
     'inkâr',
     'insan',
     'insankızını',
     'insanlı',
     'insanlık',
     'insansız',
     'intikam',
     'intikamını',
     'ipek',
     'ipleşme',
     'iplik',
     'ipnotizma',
     'irade',
     'iradeli',
     'iri',
     'iribaş',
     'irilik',
     'ırkçılık',
     'irkilme',
     'irtifa',
     'işadamı',
     'işaretçi',
     'işaretlendi',
     'işaretsiz',
     'işçi',
     'işgalci',
     'işgüzarın',
     'ısı',
     'ışıklılık',
     'ışıksız',
     'ışıltılı',
     'işim',
     'isimsiz',
     'ısıtılmamış',
     'ıskarta',
     'iskelet',
     'iskoç',
     'iskorbüt',
     'islam',
     'islamcı',
     'ıslanma',
     'ıslatma',
     'işletme',
     'işletmek',
     'işleyici',
     'ıspanak',
     'ispinoz',
     'ispiyoncu',
     'ispiyonlamak',
     'ispritizm',
     'ısrar',
     'ıssız',
     'iştah',
     'iştahla',
     'istatistik',
     'istek',
     'istekli',
     'isteksiz',
     'istemci',
     'istenmeyen',
     'isteyen',
     'istifa',
     'istifleme',
     'istihza',
     'istikrar',
     'istinat',
     'istiridye',
     'istismar',
     'istisna',
     'işveren',
     'isyan',
     'isyancı',
     'itaat',
     'itaatkâr',
     'italik',
     'ithalatçı',
     'itici',
     'itiraf',
     'itme',
     'iyi',
     'iyileşme',
     'iyileştirici',
     'iyileştirilebilir',
     'iyileştirilmesi',
     'iyileştirmek',
     'iyilik',
     'iyimser',
     'iyon',
     'iyot',
     'iyotlamak',
     'izciler',
     'izcilik',
     'ızdırap',
     'ızgara',
     'ızgaralık',
     'izleme',
     'izlemek',
     'izlenmemiş',
     'izlenmeyen',
     'izolasyon',
     'izotop',
     'jartiyer',
     'jeolog',
     'jeoloji',
     'jeolojik',
     'jigolo',
     'jirasyonları',
     'joker',
     'jokey',
     'jöle',
     'jöleli',
     'juguler',
     'jüri',
     'kaba',
     'kabaca',
     'kabahatli',
     'kabak',
     'kabakulak',
     'kabaran',
     'kabarcık',
     'kabarcıklı',
     'kabarma',
     'kabarması',
     'kabartma',
     'kabile',
     'kabiliyet',
     'kabini',
     'kabinler',
     'kablo',
     'kablolanmamış',
     'kablolu',
     'kablosuz',
     'kabotaj',
     'kabuğu',
     'kabuk',
     'kabul',
     'kaçak',
     'kaçakçılık',
     'kaçakçısı',
     'kaçamak',
     'kaçan',
     'kaçık',
     'kaçınılmazlık',
     'kaçınma',
     'kaçırma',
     'kaçmak',
     'kaçmasına',
     'kaçtı',
     'kadar',
     'kadeh',
     'kadife',
     'kadınca',
     'kadınlaştırmak',
     'kadınlık',
     'kadınsı',
     'kadınsız',
     'kadmiyum',
     'kadran',
     'kadrosu',
     'kafalı',
     'kafatası',
     'kafeinsiz',
     'kafes',
     'kafesli',
     'kafiye',
     'kafkas',
     'kağıt',
     'kahramanlık',
     'kahve',
     'kakao',
     'kaktüs',
     'kaktüsler',
     'kalabalık',
     'kalabilen',
     'kalamar',
     'kalan',
     'kalaycı',
     'kaldıraç',
     'kaldıran',
     'kaldırıcı',
     'kaldırılma',
     'kaldırılması',
     'kaldırım',
     'kaldırma',
     'kaldırmak',
     'kale',
     'kalem',
     'kalemi',
     'kalemlik',
     'kalemtıraş',
     'kalibre',
     'kalici',
     'kalınlaştırmak',
     'kalınlık',
     'kalıntısı',
     'kalıp',
     'kalır',
     'kalite',
     'kalkan',
     'kalkık',
     'kalkış',
     'kalkmak',
     'kalmış',
     'kalori',
     'kalp',
     'kalsiyum',
     'kama',
     'kambur',
     'kamçı',
     'kamera',
     'kamışı',
     'kampanya',
     'kampçı',
     'kamyon',
     'kanaatkâr',
     'kanal',
     'kanarya',
     'kanat',
     'kandırma',
     'kanepe',
     'kangren',
     'kanguru',
     'kanıt',
     'kanıtlanabilir',
     'kanıtlanmamış',
     'kanıtlanmış',
     'kanıtlayarak',
     'kanka',
     'kanlı',
     'kanola',
     'kantin',
     'kanunsuz',
     'kanyon',
     'kanyonun',
     'kaos',
     'kap',
     'kapak',
     'kapaklanmış',
     'kapamak',
     'kapanabilen',
     'kapasite',
     'kapatma',
     'kapıcı',
     'kapılmış',
     'kaplama',
     'kaplamışlardır',
     'kaplan',
     'kaplanmamış',
     'kaplanması',
     'kaplanmış',
     'kaplumbağa',
     'kapmak',
     'kaprisli',
     'kapsül',
     'kaptan',
     'kaput',
     'karaağaç',
     'karabuğday',
     'karaciğer',
     'karada',
     'karadaki',
     'karahindiba',
     'karakol',
     'karakter',
     'karalama',
     'karamel',
     'karamsar',
     'karamsarlık',
     'karanfil',
     'karanlık',
     'karar',
     'kararlı',
     'kararmaz',
     'kararname',
     'kararsız',
     'karartılmış',
     'karat',
     'karavan',
     'karbon',
     'karde',
     'kardelen',
     'kardeş',
     'kardeşçe',
     'kardeşleri',
     'kardinal',
     'kargaşa',
     'kargo',
     'karides',
     'karikatür',
     'karın',
     'karıncalanma',
     'karışıklık',
     'karıştır',
     'karıştırılmamış',
     'karıştırılmış',
     'karıştırma',
     'karıştırmak',
     'karmakarışık',
     'karnaval',
     'karne',
     'karnında',
     'karşı',
     'karşılaşma',
     'karşılaştırmak',
     'karşılayıcı',
     'karşılıklı',
     'karşısında',
     'karşıt',
     'kartal',
     'kartel',
     'kartlar',
     'karton',
     'kartpostal',
     'kartuş',
     'kas',
     'kasa',
     'kaset',
     'kasık',
     'kaşık',
     'kaşımak',
     'kasırga',
     'kask',
     'kaskatı',
     'kaşkorse',
     'kasten',
     'kasvetli',
     'katakomp',
     'katalize',
     'katalizör',
     'katalog',
     'katarakt',
     'katatonik',
     'katedral',
     'katı',
     'katılık',
     'katılımcı',
     'katılmıyorum',
     'katip',
     'katır',
     'katışıksız',
     'katlanmış',
     'katliamın',
     'kavak',
     'kavga',
     'kavrama',
     'kavramak',
     'kavramı',
     'kavrayıcıyı',
     'kavrulmak',
     'kavrulmamış',
     'kavurucu',
     'kayakçı',
     'kayalık',
     'kayar',
     'kaybı',
     'kaybolursun',
     'kaydedilmemiş',
     'kaydırıcı',
     'kaydırma',
     'kaygan',
     'kayısı',
     'kayıt',
     'kayıtsızca',
     'kayma',
     'kaymak',
     'kaymaktaşı',
     'kaynak',
     'kaynama',
     'kaynayan',
     'kaypak',
     'kayrak',
     'kaz',
     'kazanan',
     'kazanç',
     'kazançlar',
     'kazançlı',
     'kazandırmakta',
     'kazanılmamış',
     'kazanılmış',
     'kazanıyor',
     'kazı',
     'kazıma',
     'kazıntı',
     'kazmak',
     'kebap',
     'keçeleşmiş',
     'kederli',
     'kedi',
     'kefal',
     'kefen',
     'kehribar',
     'kek',
     'kekeleme',
     'keklik',
     'kelepçe',
     'kelime',
     'kelt',
     'keman',
     'kement',
     'kemikli',
     'kemiksi',
     'kemiksiz',
     'kemirmek',
     'kemoterapi',
     'kenar',
     'kendim',
     'kendini',
     'kent',
     'kentsel',
     'kepçe',
     'kepek',
     'kerata',
     'kereste',
     'kerevides',
     'kerevit',
     'kereviz',
     'kertenkele',
     'kese',
     'keseli',
     'keşfedilmemiş',
     'keşfetmek',
     'kesilmek',
     'kesilmemiş',
     'kesin',
     'kesinlikle',
     'kesinti',
     'keşişler',
     'kesit',
     'keskin',
     'keskinlik',
     'kesmek',
     'kestanesi',
     'kesti',
     'ketçap',
     'keyfi',
     'keyifle',
     'keyifli',
     'keyifsiz',
     'kibarca',
     'kibir',
     'kibirli',
     'kıkırdak',
     'kıkırdama',
     'kıkırdayan',
     'kıkırdayarak',
     'kil',
     'kıl',
     'kılavuz',
     'kiler',
     'kilidi',
     'kılıf',
     'kilim',
     'kilobayt',
     'kiloluk',
     'kilometre',
     'kilovat',
     'kımıldamaz',
     'kimlik',
     'kimse',
     'kimseniz',
     'kimyasallar',
     'kin',
     'kına',
     'kınama',
     'kinci',
     'kinetik',
     'kıpırdamadan',
     'kıpırdamak',
     'kıpırdanmayı',
     'kiracı',
     'kiralama',
     'kiralanabilir',
     'kiralanan',
     'kiraya',
     'kiri',
     'kırıcı',
     'kırık',
     'kırılgan',
     'kırılganlık',
     'kırılmamış',
     'kırım',
     'kırıntı',
     'kırışık',
     'kırışıklık',
     'kırışma',
     'kırışmaya',
     'kirletmek',
     'kirletmene',
     'kirli',
     'kirlilik',
     'kirpi',
     'kirpik',
     'kırpıştırdıktan',
     'kırpma',
     'kırpmak',
     'kırsal',
     'kış',
     'kısaca',
     'kısaltın',
     'kısaltması',
     'kısayol',
     'kısım',
     'kısıtlı',
     'kıskaçlanmamış',
     'kıskanç',
     'kıskanılacak',
     'kışkırtmak',
     'kışla',
     'kısmen',
     'kişneme',
     'kişniş',
     'kıst',
     'kıt',
     'kitap',
     'kitleler',
     'kıtlığı',
     'kıtlık',
     'kivi',
     'kıvırcık',
     'kıvraklık',
     'kıvranmak',
     'kıvrılmış',
     'kıyafeti',
     'kıyaslanmak',
     'kıyı',
     'kıymık',
     'kızak',
     'kızarmak',
     'kızarmış',
     'kızartma',
     'kızböceği',
     'kızdırmak',
     'kızgın',
     'kızgınlıkla',
     'klarnet',
     'klav',
     'klik',
     'kliniği',
     'klips',
     'klon',
     'klonlama',
     'klor',
     'klorür',
     'koagülant',
     'kobalt',
     'koç',
     'koca',
     'kodlamak',
     'köhne',
     'kök',
     'kokla',
     'köklü',
     'kokmuş',
     'koku',
     'kokulu',
     'kokuşmuş',
     'kokusuz',
     'kol',
     'kola',
     'kolaj',
     'kolayca',
     'kolaylaştırılması',
     'kolaylığı',
     'köle',
     'kolları',
     'koloni',
     'kolonide',
     'kolonileştirmediği',
     'kolsuz',
     'koltuk',
     'koltukaltı',
     'kolu',
     'kolye',
     'koma',
     'kombinezon',
     'komi',
     'komik',
     'komisyoncu',
     'komitesi',
     'komodin',
     'komodor',
     'kompakt',
     'kompaktör',
     'kompozit',
     'kompres',
     'kömürleşmiş',
     'konç',
     'kondüktör',
     'konfeti',
     'konfor',
     'konfüzyon',
     'kongre',
     'koni',
     'konik',
     'konnektör',
     'konser',
     'konserve',
     'konservesi',
     'konsol',
     'kontrol',
     'kontrplak',
     'kontur',
     'kontüzyonu',
     'konu',
     'konuşkan',
     'konuşma',
     'konuşmak',
     'konuşulmayan',
     'konuşulmuş',
     'konut',
     'köpeği',
     'köpek',
     'kopması',
     'köprü',
     'köprülü',
     'köpük',
     'köpüklü',
     'köpüren',
     'kopyalamak',
     'kopyalanan',
     'kor',
     'kör',
     'kordonu',
     'körfez',
     'köri',
     'koridor',
     'korkak',
     'korkmuş',
     'korkuluk',
     'korkunç',
     'korkusuz',
     'korkutmak',
     'korkutucu',
     'kornea',
     'koroner',
     'korsan',
     'korse',
     'korteks',
     'korumak',
     'korusu',
     'korusun',
     'koruyucusu',
     'köşe',
     'köşeli',
     'koşer',
     'köşk',
     'koskocaman',
     'köstek',
     'koşu',
     'koşucu',
     'kot',
     'kötü',
     'kötüleşen',
     'kötülük',
     'kova',
     'kovalamaca',
     'kovalamak',
     'kovma',
     'köy',
     'koyulaşması',
     'koyun',
     'koz',
     'kozmik',
     'kral',
     'kraliyet',
     'kramp',
     'kranial',
     'krank',
     'kredi',
     'kredili',
     'krem',
     'kremalı',
     'krep',
     'kriket',
     'kripton',
     'kristal',
     'kriterleri',
     'kroki',
     'krom',
     'kronometre',
     'kroşe',
     'kuantum',
     'kübik',
     'küçük',
     'küçüklük',
     'küçülen',
     'küçülmüfltürler',
     'küçültmek',
     'küçültücü',
     'küçümseme',
     'küçümsemeye',
     'küçümseyen',
     'kuduz',
     'küf',
     'küflülük',
     'küfür',
     'kuğu',
     'küheylânım',
     'kuka',
     'kukla',
     'kükürt',
     'kulağakaçan',
     'kulak',
     'kulaklık',
     'kulaklıklar',
     'külçe',
     'kulis',
     'kullanıcı',
     'kullanılabilir',
     'kullanılan',
     'kullanılmayan',
     'kullanılmış',
     'kullanımı',
     'kullanışsız',
     'kullanmak',
     'küller',
     'küllü',
     'kültür',
     'kültürel',
     'kulübe',
     'kulübesindeki',
     'kuluçka',
     'kum',
     'kumandan',
     'kumar',
     'kumarhane',
     'kumaş',
     'kumaşçılık',
     'kümelenmiş',
     'kumlama',
     'kumlu',
     'kumru',
     'kumtaşı',
     'kundak',
     'kundakçılık',
     'künt',
     'küp',
     'kupa',
     'küpe',
     'kupon',
     'kur',
     'kurabiye',
     'kural',
     'kuramlaştırmak',
     'küratörü',
     'kurban',
     'kurcalamaya',
     'kurdele',
     'küre',
     'kürek',
     'küresel',
     'kurgu',
     'kurma',
     'kurmak',
     'kurnaz',
     'kurnazca',
     'kurşunsuz',
     'kurt',
     'kurtaran',
     'kurtardı',
     'kurtarıcı',
     'kurtarma',
     'kurtarmaz',
     'kurtçuk',
     'kurtulma',
     'kurtulmak',
     'kurtuluş',
     'kuru',
     'kurucu',
     'kurucularından',
     'kurucusu',
     'kurumamış',
     'kurumsal',
     'kuruntu',
     'kuruş',
     'kurutucu',
     'kurutulmuş',
     'kurye',
     'kuşak',
     'kuşatılmış',
     'kuşatmaktadırlar',
     'kuşkonmaz',
     'küskünlük',
     'kusmak',
     'küstah',
     'kusur',
     'kusursuzca',
     'kütle',
     'kutsal',
     'kutsallığı',
     'kutsallıktan',
     'kutsanmamış',
     'kütüphane',
     'kütüphaneci',
     'kutupsal',
     'kutusuz',
     'kuvvetlice',
     'kuyumcu',
     'kuzgun',
     'ladin',
     'lagün',
     'lahana',
     'lahza',
     'laik',
     'lale',
     'lanetlemek',
     'lapası',
     'laubali',
     'lavabo',
     'lavanta',
     'leğen',
     'lehçe',
     'lehine',
     'leke',
     'lekelenme',
     'lekelenmiş',
     'lekeli',
     'lekesiz',
     'letarji',
     'levha',
     'levrek',
     'levye',
     'leylak',
     'leziz',
     'lezzetli',
     'lif',
     'lıklık',
     'likör',
     'liman',
     'limon',
     'limonata',
     'linolyum',
     'lirizm',
     'lisans',
     'liste',
     'listelenmemiş',
     'lokali',
     'lokanta',
     'loş',
     'lows',
     'lüks',
     'lumbaragzi',
     'maaş',
     'maaşlı',
     'madde',
     'maderşahi',
     'magnezyum',
     'maharet',
     'maharetle',
     'mahcup',
     'mahfaza',
     'mahkemesi',
     'mahrum',
     'mahvetmek',
     'majesteleri',
     'makale',
     'makara',
     'makarna',
     'makbul',
     'makine',
     'makinist',
     'maksimum',
     'makyaj',
     'mala',
     'malç',
     'mali',
     'malikane',
     'maliyet',
     'malzeme',
     'malzemesi',
     'mamografi',
     'manastır',
     'mancınık',
     'manda',
     'mandal',
     'mandalina',
     'mandıra',
     'manevi',
     'manikür',
     'manolya',
     'mantar',
     'mantı',
     'mantıklı',
     'manuel',
     'manyetik',
     'manyetize',
     'manyetizma',
     'manzara',
     'marangoz',
     'marangozluk',
     'maraton',
     'margarin',
     'marjı',
     'market',
     'marksizm',
     'marmelat',
     'marş',
     'mart',
     'marul',
     'masaj',
     'masal',
     'masaüstü',
     'masif',
     'maskesiz',
     'maskot',
     'mason',
     'masraflar',
     'matematik',
     'matris',
     'maun',
     'mavimsi',
     'mavna',
     'maya',
     'mayası',
     'maydanoz',
     'mayınları',
     'mayo',
     'mazereti',
     'mazgal',
     'meç',
     'medeni',
     'mehtaplı',
     'mekan',
     'mektup',
     'melek',
     'melez',
     'meltem',
     'meme',
     'memeli',
     'memnun',
     'memnuniyetle',
     'mendil',
     'menekşe',
     'mengene',
     'menhol',
     'mera',
     'mercan',
     'merdiven',
     'merhem',
     'merhum',
     'mermer',
     'mermerler',
     'mesafe',
     'mesai',
     'mesane',
     'meşe',
     'mesele',
     'meslek',
     'mest',
     'metro',
     'mevcut',
     'meyve',
     'mezarlar',
     'mezarlık',
     'meze',
     'mezunlar',
     'midilli',
     'mikropsuz',
     'miktar',
     'milletvekili',
     'mimik',
     'mıntıkanız',
     'minyon',
     'miras',
     'mirası',
     'mirasyedi',
     'mırıldanarak',
     'mırıltı',
     'mırlamak',
     'misal',
     'misillemede',
     'misk',
     'mistifiye',
     'mizahçı',
     'mobil',
     'mobilet',
     'model',
     'modül',
     'modülatör',
     'modüler',
     'mol',
     'moleküler',
     'molekülü',
     'moloz',
     'monitör',
     'monoksit',
     'monolog',
     'monoray',
     'monoton',
     'montaj',
     'monte',
     'mor',
     'moral',
     'morbid',
     'morbidite',
     'morfin',
     'morg',
     'morina',
     'mors',
     'mortalite',
     'morumsu',
     'mösyö',
     'motivasyon',
     'motokros',
     'mozaik',
     'muafiyet',
     'mücâdele',
     'mücadele',
     'mücevher',
     'müeyyide',
     'muhabbetkuşu',
     'muhabir',
     'muhallebi',
     'muhtaç',
     'muhtemel',
     'muhtemelen',
     'muhteşem',
     'mühürsüz',
     'müjdelemek',
     'mükellefi',
     'mükemmelleştirilmiş',
     'mukim',
     'mulitokremnezemist',
     'mum',
     'mümkün',
     'mumya',
     'mumyalanmış',
     'münakaşa',
     'münzevi',
     'musallat',
     'müshil',
     'musibet',
     'muska',
     'musluk',
     'musluklar',
     'muson',
     'müsrif',
     'müstakil',
     'müşteri',
     'mutasyon',
     'müteahhit',
     'mütevazı',
     'mütevelli',
     'mutfak',
     'müthiş',
     'mutlak',
     'mutlaka',
     'mutlu',
     'mutluluk',
     'mutlulukla',
     'mutsuz',
     'muz',
     'müzakere',
     'müze',
     'müzik',
     'muziptir',
     'nabız',
     'nadir',
     'nadiren',
     'nakit',
     'nane',
     'nanometre',
     'narin',
     'nasihatli',
     'nasır',
     'navlun',
     'naylon',
     'nazik',
     'nazikçe',
     'nebülizatör',
     'neden',
     'nedeni',
     'nedensel',
     'nedensellik',
     'nefes',
     'nefis',
     'nefret',
     'nektar',
     'nem',
     'nemlendirip',
     'nemli',
     'nemlilik',
     'neredeyse',
     'nergis',
     'neşe',
     'neşeli',
     'nesne',
     'netice',
     'netleştirmek',
     'netlik',
     'nevrotik',
     'nevroz',
     'neyse',
     'nezaketsiz',
     'nihai',
     'nikâh',
     'nikotin',
     'nine',
     'ninni',
     'nisan',
     'nişanlı',
     'nişasta',
     'nispet',
     'nitelik',
     'nitelikli',
     'noktalama',
     'noktalı',
     'nöroloji',
     'nöron',
     'nostaljik',
     'nötron',
     'nükleer',
     'nüks',
     'nükseden',
     'numara',
     'numaracı',
     'nurdur',
     'obsesif',
     'obua',
     'obur',
     'öbür',
     'ocak',
     'öcü',
     'odaklanmamış',
     'ödeme',
     'ödenecek',
     'ödenek',
     'ödenmemiş',
     'ödeyerek',
     'ödül',
     'ödünç',
     'öfke',
     'öfkelendirecek',
     'öfkeyle',
     'oğlak',
     'öğrenci',
     'öğürme',
     'okşama',
     'okşamak',
     'oksidan',
     'oksidasyon',
     'oksijen',
     'oksitleyici',
     'öksürük',
     'oktan',
     'okudu',
     'okültist',
     'okunaklı',
     'okunmamış',
     'okyanus',
     'olabilir',
     'olağan',
     'olasılıkla',
     'olay',
     'ölçek',
     'ölçekleme',
     'ölçme',
     'ölçüde',
     'ölçüsüz',
     'öldürülen',
     'olgun',
     'olgunlaşan',
     'olgunlaşanlar',
     'olgunlaşma',
     'olgunlaşmamış',
     'olgunlaşmış',
     'olgunluğu',
     'olgunluk',
     'olgusal',
     'olimpiyatlar',
     'oluk',
     'ölümcül',
     'olumlu',
     'olumsuz',
     'ölümsüz',
     'olumsuzluk',
     'ölümü',
     'oluşan',
     'oluşturma',
     'oluşturmak',
     'oluşturucusu',
     'omurgalı',
     'omurları',
     'onadı',
     'onarım',
     'onay',
     'onaylamak',
     'onbaşı',
     'önbellek',
     'onbir',
     'önce',
     'önceki',
     'öncelikle',
     'ondalık',
     'ondokuz',
     'önek',
     'önemli',
     'önemsenmemiş',
     'önemsiz',
     'öneri',
     'önermek',
     'öngörmediğini',
     'oniks',
     'ön-ıslatma',
     'önlemek',
     'önlük',
     'ons',
     'önsöz',
     'onüç',
     'önümüzdeki',
     'onyıl',
     'opaklık',
     'operasyon',
     'öpülesi',
     'öpüşmek',
     'öpüşüyor',
     'oradaki',
     'ördek',
     'ordu',
     'örgüsüz',
     'ormansızlaştırmak',
     'örnek',
     'örneklem',
     'örnekleme',
     'örneklemini;',
     'ornitorenk',
     'örs',
     'ortak',
     'ortalama',
     'örten',
     'örtmek',
     'örtük',
     'oruç',
     'örümcek',
     'osmoz',
     'otantik',
     'otistik',
     'otizm',
     'otlama',
     'otlar',
     'otoban',
     'otomat',
     'otomatik',
     'otomobil',
     'otopark',
     'oturaklı',
     'oturma',
     'oturumları',
     'otuz',
     'ovalamak',
     'ovalanmış',
     'ovma',
     'övme',
     'oyalamak',
     'oylama',
     'oyma',
     'oymacı',
     'oymacılık',
     'oynadığı',
     'oynama',
     'oynanabilir',
     'oyulmuş',
     'oyun',
     'oyuncak',
     'oyuncaklıdır',
     'oyuncu',
     'özdeş',
     'özel',
     'özeliği',
     'özelleştir',
     'özelleştirmek',
     'özenli',
     'özensiz',
     'özenti',
     'özetle',
     'özgürce',
     'özgürlük',
     'özlem',
     'özlü',
     'ozon',
     'özürlü',
     'özveri',
     'özverili',
     'paçavra',
     'pahalı',
     'palan',
     'palavra',
     'palavracı',
     'palpitat',
     'palto',
     'pamuk',
     'panço',
     'pandemik',
     'pandomim',
     'paniginizin',
     'panik',
     'pankreas',
     'panoramik',
     'panter',
     'pantolon',
     'panzehir',
     'papa',
     'papağan',
     'papatya',
     'papaz',
     'papirüs',
     'parabol',
     'paradoks',
     'paragöz',
     'paragraf',
     'paralizi',
     'paramedik',
     'parametre',
     'parasal',
     'parasız',
     'paraşüt',
     'parazit',
     'parçalama',
     'parçalayıcı',
     'parfüm',
     'parıltı',
     'parıltılı',
     'parıltısı',
     'parıltıyı',
     'parke',
     'parladı',
     'parlak',
     'parlama',
     'parlamak',
     'parlatıcısı',
     'parlıyor',
     'parodi',
     'parola',
     'parsel',
     'parşömen',
     'parti',
     'pas',
     'pasajı',
     'pasaport',
     'pasif',
     'pasifik',
     'pasifist',
     'pasifizm',
     'paskalya',
     'paslanabilirler',
     'paslanmak',
     'paslanmaz',
     'paspas',
     'pastırması',
     'pat',
     'patavatsız',
     'patika',
     'patinajcı',
     'patlama',
     'patlamak',
     'patlatıcı',
     'patlayabilir',
     'patlayana',
     'patlıcan',
     'patrik',
     'patron',
     'patronise',
     'pay',
     'paylaş',
     'pazarlıkçı',
     'pazartesi',
     'peçete',
     'peder',
     'pediatrik',
     'pedikür',
     'pedometre',
     'pekan',
     'peki',
     'pekiştirmek',
     'pektin',
     'pelerin',
     'pelikan',
     'pembe',
     'pençe',
     'pençeliyor',
     'perakende',
     'perçinleme',
     'perde',
     'periskop',
     'perkolatm',
     'perma',
     'peroksit',
     'perşembe',
     'personel',
     'pervane',
     'pervasızca',
     'pes',
     'peşinde',
     'pestisit',
     'petrol',
     'petunya',
     'peynir',
     'pezosu',
     'pijama',
     'pil',
     'pilotsuz',
     'piramit',
     'pırıltı',
     'pirinç',
     'pirzola',
     'pis',
     'pisboğaz',
     'pısırık',
     'pişirme',
     'piskoposluk',
     'pislik',
     'pişman',
     'pişmanım',
     'pişmemiş',
     'pişmiş',
     'pisti',
     'piton',
     'planlayıcısı',
     'planör',
     'plansız',
     'plastik',
     'platin',
     'platonik',
     'plazma',
     'pli',
     'plütonyum',
     'podyum',
     'polimer',
     'polis',
     'politika',
     'pomza',
     'popo',
     'popüler',
     'portföy',
     'poşet',
     'posta',
     'postu',
     'poz',
     'pozlar',
     'pranga',
     'prenses',
     'primat',
     'prizma',
     'proaktif',
     'probiyotik',
     'profesör',
     'profil',
     'prognoz',
     'projektör',
     'prolog',
     'prömiyeri',
     'promoteri',
     'prosedür',
     'prototip',
     'protozoon',
     'psişik',
     'puanlama',
     'pudramsı',
     'pul',
     'pullar',
     'pulluk',
     'pulmoner',
     'püresi',
     'pürist',
     'püriten',
     'pürüzsüz',
     'pürüzsüzlük',
     'puset',
     'püskül',
     'püskürtme',
     'püskürtmesi',
     'puslu',
     'pusu',
     'putperest',
     'radyal',
     'radyasyon',
     'radyatör',
     'radyo',
     'raf',
     'rafine',
     'rafineri',
     'rafları',
     'rağmen',
     'rahat',
     'rahatça',
     'rahatlatma',
     'rahatlatmak',
     'rahatlayın',
     'rahatsız',
     'rahim',
     'rakamı',
     'rakip',
     'rakipsiz',
     'rakun',
     'ralli',
     'rampa',
     'rant',
     'rantlar',
     'raptiye',
     'rasgele',
     'reaksiyon',
     'reaktif',
     'reaktör',
     'reçel',
     'reçetelemek',
     'reddederek',
     'reddet',
     'reddetmek',
     'referans',
     'refleks',
     'reflektör',
     'reform',
     'reformasyon',
     'reformcu',
     'regülatör',
     'rehabilitasyon',
     'rehberlik',
     'rehidrate',
     'rehin',
     'rejim',
     'rektal',
     'rektifiye',
     'remisyon',
     'render',
     'resitali',
     'restoranıdır',
     'ret',
     'retina',
     'reverans',
     'revize',
     'revizyon',
     'rezalet',
     'rezidüel',
     'rezonans',
     'rıhtım',
     'rilen',
     'ringa',
     'robotik',
     'roket',
     'röle',
     'roma',
     'römorkör',
     'rötuş',
     'rövanş',
     'rozet',
     'ruhlar',
     'ruhlu',
     'rulet',
     'rüşvet',
     'rütbesiz',
     'rutin',
     'rutubetli',
     'rüyasız',
     'rüzgar',
     'rüzgârlı',
     'saat',
     'sabahlık',
     'sabır',
     'sabırlı',
     'sabırsız',
     'sabit',
     'sabitlenmemiş',
     'şablon',
     'sabotaj',
     'sabun',
     'saçak',
     'saçmalamak',
     'sadaka',
     'sadakat',
     'sadece',
     'sadık',
     'saf',
     'şafak',
     'safkan',
     'saflığı',
     'saflık',
     'safran',
     'şaft',
     'sağgörüsüz',
     'sağır',
     'sağırlık',
     'sağlam',
     'sağlama',
     'sağlamak',
     'sağlamlaştırmak',
     'sağlanan',
     'sağlayıcı',
     'sağlıksız',
     'şahane',
     'şahin',
     'sahipsiz',
     'şahlanmak',
     'sahne',
     'sahnede',
     'sahte',
     'şair',
     'şaka',
     'şakacı',
     'sakar',
     'sakat',
     'sakatat',
     'sakin',
     'sakini',
     'sakinleştirmek',
     'sakızlı',
     'saklamak',
     'şakşak',
     'saksofon',
     'sal',
     'şal',
     'salak',
     'salam',
     'salata',
     'salatalık',
     'saldırgan',
     'saldırmak',
     'salgın',
     'salı',
     'sallama',
     'sallanan',
     'sallanır',
     'sallanma',
     'sallanmak',
     'sallanmanin',
     'sallantı',
     'sallayarak',
     'salmak',
     'salonu',
     'salya',
     'salyalı',
     'salyangoz',
     'salyangozdur',
     'saman',
     'şamata',
     'samimi',
     'samimiyet',
     'sampiyonu',
     'şampuan',
     'şan',
     'sana',
     'sanat',
     'sancak',
     'sancı',
     'sandalye',
     'sandık',
     'sandıklar',
     'sanık',
     'sanılan',
     'şanlı',
     'şans',
     'şanslılık',
     'şanssız',
     'sapan',
     'şaperondan',
     'şapırtı',
     'şapkasız',
     'şapkaya',
     'sapkın',
     'sapkınlığın',
     'saplanmış',
     'sapma',
     'saptırıcı',
     'şarapnel',
     'saray',
     'sardalya',
     'sardunya',
     'sarfınazar',
     'sarılık',
     'sarılıyoruz',
     'sarılmak',
     'sarımsak',
     'şarjlı',
     'sarkan',
     'şarkıcı',
     'sarkık',
     'sarkıtmak',
     'sarkmanın',
     'sarkmış',
     'sarmaşık',
     'sarsıcı',
     'sarsılmadan',
     'sarsıntı',
     'şart',
     'şaşı',
     'şaşırmışça',
     'şaşırtıcı',
     'şaşırtmak',
     'şaşırttığım',
     'şaşkın',
     'saten',
     'satici',
     'satıcı',
     'satılmamış',
     'satirik',
     'satış',
     'satranç',
     'savan',
     'savaş',
     'savaşçı',
     'savrulma',
     'savunma',
     'savunmasız',
     'savurgan',
     'savurganlık',
     'sayfalama',
     'saygı',
     'saygılı',
     'saygın',
     'saygısız',
     'sayıklıyor',
     'sayılabilir',
     'sayılmamış',
     'sayımı',
     'sayısal',
     'sayısız',
     'sebat',
     'şebeke',
     'sebze',
     'seçici',
     'seçilen',
     'seçilmemiş',
     'seçim',
     'seçimi',
     'seçkinler',
     'seçme',
     'seçmelere',
     'seçmeli',
     'seçmen',
     'seçmenize',
     'sedasyon',
     'sedir',
     'sedye',
     'şef',
     'sefer',
     'seferberlik',
     'sefil',
     'şefkâtsiz',
     'seğirme',
     'şeker',
     'şekerlenmiş',
     'şekerli',
     'şekil',
     'şekillendirmek',
     'sekizgen',
     'sekme',
     'sektör',
     'selam',
     'selamlamak',
     'seli',
     'şema',
     'semantik',
     'şematik',
     'seminer',
     'şempanze',
     'sempati',
     'semptom',
     'şemsiye',
     'şen',
     'senaryo',
     'senato',
     'senatör',
     'sendromu',
     'senet',
     'senfoni',
     'senfonik',
     'şenlik',
     'sentetik',
     'sentezi',
     'sepet',
     'septik',
     'sepya',
     'seramik',
     'serbestlik',
     'şeref',
     'sermek',
     'serpilmiş',
     'serpiştirmek',
     'sersem',
     'sersemlik',
     'serseri',
     'serserilik',
     'sert',
     'sertifikalı',
     'sertleştirici',
     'sertleştirilmiş',
     'sertleştirme',
     'sertlik',
     'servis',
     'ses',
     'seslendirmek',
     'sesli',
     'sessiz',
     'sevdası',
     'sevdirmek',
     'severek',
     'sevgi',
     'sevgilim',
     'şevhetli',
     'sevilmemiş',
     'sevilmeyen',
     'sevimli',
     'sevimsiz',
     'sevinçle',
     'sevinçleri',
     'sevinçli',
     'sevinirler',
     'sevinme',
     'seviye',
     'sevme',
     'şey',
     'seyahat',
     'seyirci',
     'seyreltik',
     'seyreltilmemiş',
     'şeytani',
     'sezaryen',
     'sfenks',
     'sibirya',
     'sıcaklık',
     'sicim',
     'sıçradı',
     'sıçrama',
     'sıçramak',
     'sıçramış',
     'şiddeti',
     'şiddetle',
     'şiddetli',
     'sıfır',
     'sıfırla',
     'şifre',
     'şifrelemek',
     'şifreli',
     'sığ',
     'sığınağın',
     'sığınak',
     'sığır',
     'sığırlar',
     'sigortasız',
     'sıhhi',
     'sihirbaz',
     'şiir',
     'sık',
     'şık',
     'şikayet',
     'sıkı',
     'sıkıca',
     'sıkıcı',
     'sıkılaştırmak',
     'sıkıntı',
     'sıkışık',
     'sıkıştırılır',
     'sıkıştırılmalı',
     'sıkıştırma',
     'şıklığı',
     'sıklık',
     'sıkma',
     'silahlı',
     'sildim',
     'silecek',
     'silgi',
     'silika',
     'silikon',
     'silin',
     'silindir',
     'silinebilir',
     'silinti',
     'silkinme',
     'silme',
     'silsile',
     'şımarık',
     'şımartılmış',
     'simgesi',
     'simit',
     'sinaps',
     'sinema',
     'sinerji',
     'sınıf',
     'sınıflandırılmamış',
     'sınır',
     'sınırdaş',
     'sınırlama',
     'sinirli',
     'sinirlilik',
     'sınırsız',
     'sinopsis',
     'sinsi',
     'sinyorita',
     'sipariş',
     'sıra',
     'sıradan',
     'sıradışı',
     'sıralama',
     'sıralandığını',
     'sırasında',
     'sırdaş',
     'sırık',
     'sırım',
     'sırıtan',
     'sırıtma',
     'sirk',
     'şirket',
     'sırnaşıklık',
     'sırsız',
     'sırt',
     'sis',
     'şiş',
     'şişe',
     'şişeleme',
     'şişirilmiş',
     'sıska',
     'şişkinlik',
     'şişme',
     'sismik',
     'şist',
     'sistem',
     'sitem',
     'sitoplast',
     'sitoplazma',
     'sitrik',
     'sıva',
     'sıvı',
     'sivil',
     'sıvılaştırılması',
     'sivilce',
     'sivilceli',
     'sivri',
     'sivrilen',
     'sivrisinek',
     'siyahımsı',
     'siyam',
     'sıyırma',
     'sızdı',
     'sızıntılı',
     'sızıntısı',
     'sızladı',
     'sızlama',
     'skandal',
     'sloganı',
     'smokin',
     'şnorkel',
     'sofra',
     'soğan',
     'soğuk',
     'soğukkanlılık',
     'söğüt',
     'sohbet',
     'şöhret',
     'sök',
     'şok',
     'sokak',
     'sökecekler',
     'sökücü',
     'sokulmak',
     'sökülmüş',
     'sökün',
     'sollamak',
     'solma',
     'solmaya',
     'solmuş',
     'solucan',
     'soluk',
     'solungaçları',
     'şom',
     'sömürge',
     'somut',
     'somutlaştırmak',
     'son',
     'sona',
     'sonbahar',
     'sondaj',
     'sondalama',
     'sonlandırıcı',
     'sonlu',
     'sönmüş',
     'sonraki',
     'sonrasının',
     'sonsöz',
     'sonsuz',
     'sonsuzluk',
     'sonuç',
     'sonuçlandırmak',
     'sönük',
     'sörfçü',
     'sorgu',
     'şort',
     'sorumlu',
     'sorun',
     'sos',
     'şövale',
     'şövalyelik',
     'şovmen',
     'soyadı',
     'söyle',
     'söylem',
     'söylemek',
     'söylenmemiş',
     'söylenti',
     'söyleyivermek',
     'soymaktan',
     'soytarı',
     'soyu',
     'soyulmamış',
     'soyunmak',
     'sözcelemek',
     'sözcü',
     'sözle',
     'sözler',
     'sözleşme',
     'spektrumu',
     'spiker',
     'spor',
     'sportif',
     'spot',
     'sprey',
     'stadyum',
     'statik',
     'steril',
     'sterilite',
     'sterlini',
     'stil',
     'stilist',
     'stilize',
     'strateji',
     'stratejik',
     'stres',
     'stüdyo',
     'subarktik',
     'sübvansiyon',
     'suçlarlar',
     'suçlayarak',
     'suçlu',
     'suflör',
     'şükran',
     'sükse',
     'sükseli',
     'sulak',
     'sulanmasında',
     'sülfat',
     'sülfit',
     'sülfürik',
     'sulu',
     'sululuk',
     'sümüklü',
     'sunana',
     'sünger',
     'süngerimsi',
     'şunlar',
     'süpermen',
     'süpernova',
     'şüpheci',
     'şüpheli',
     'şüphelilik',
     'şüpheyle',
     'süpürge',
     'süpürme',
     'süpürüldü',
     'sürat',
     'suratsız',
     'sürdü',
     'sürdürmek',
     'süreç',
     'sürece',
     'süreci',
     'sürekli',
     'süresi',
     'sürgün',
     'sürme',
     'sürmüş',
     'sürpriz',
     'sürtme',
     'sürtmelere',
     'sürtünme',
     'sürücü',
     'sürüklenirken',
     'sürüklenme',
     'sürükleyerek',
     'sürülebilir',
     'sürüngen',
     'sürüntü',
     'şurup',
     'sürüsü',
     'sus',
     'susam',
     'susamış',
     'suşi',
     'süssüz',
     'susturucu',
     'susturulmuş',
     'susuz',
     'süvari',
     'süzgeç',
     'süzüntü',
     'svingci',
     'taahhüt',
     'tabak',
     'tabakhane',
     'tabaklanmış',
     'tabanca',
     'tablolar',
     'tabur',
     'tabut',
     'taç',
     'tahmin',
     'tahmincisi',
     'tahrif',
     'tahsildar',
     'tahta',
     'tahtada',
     'tahvil',
     'takas',
     'takdir',
     'takıldı',
     'takılmak',
     'takıntı',
     'takıntılı',
     'takla',
     'taklit',
     'takma',
     'takmak',
     'takoz',
     'taksici',
     'taktik',
     'talebiyle',
     'talip',
     'talk',
     'tamam',
     'tamirci',
     'tamircilik',
     'tampon',
     'tane',
     'tanı',
     'tanıdık',
     'tanımak',
     'tanımlama',
     'tanımlamak',
     'tanımsız',
     'tanıtım',
     'tankı',
     'tanrı',
     'tantana',
     'tapan',
     'tapyoka',
     'tarafsız',
     'taraftar',
     'tarama',
     'tarayıcı',
     'tarayıcılarının',
     'tarçın',
     'tarihsiz',
     'tartışılabilir',
     'tartışma',
     'tartışmak',
     'taş',
     'tasarımcı',
     'tasarımı',
     'tasarlama',
     'tasarlanmış',
     'tasarruf',
     'tasarruflar',
     'tasdik',
     'tasfiye',
     'taşı',
     'taşıma',
     'taşımak',
     'taşınabilir',
     'taşınmaz',
     'taşlama',
     'taşlı',
     'taşlık',
     'taşma',
     'taşra',
     'tat',
     'tatil',
     'tatlandırıcı',
     'tatlı',
     'tatlılıkla',
     'tatma',
     'tava',
     'tavır',
     'tavşan',
     'tavuk',
     'tay',
     'tayfa',
     'tayt',
     'taze',
     'tazı',
     'tazmin',
     'tebrik',
     'tebrikler',
     'tecrit',
     'tedarikçi',
     'tedbirsiz',
     'tedirgin',
     'tehdit',
     'tehlike',
     'tehlikeli',
     'tek',
     'tekdüze',
     'tekel',
     'tekerleme',
     'tekeşlilik',
     'tekil',
     'tekir',
     'tekne',
     'tekrar',
     'tekrarlanan',
     'tekrarlayıcı',
     'tekvando',
     'telaffuz',
     'telafi',
     'telef',
     'tellalı',
     'tema',
     'tembel',
     'tembellik',
     'temelde',
     'temeli',
     'temeller',
     'temelleri',
     'teminatsız',
     'temiz',
     'temize',
     'temizleyici',
     'temizlik',
     'temkinli',
     'temmuz',
     'tempolu',
     'temsilci',
     'temsilcileri',
     'tenha',
     'tente',
     'tenzil',
     'teoloji',
     'tepecik',
     'tepeli',
     'tepesinde',
     'tepki',
     'tepsi',
     'ter',
     'terbiye',
     'terbiyeli',
     'terbiyesiz',
     'tercih',
     'tereddüt',
     'termos',
     'ters',
     'terslendi',
     'teşekkür',
     'teselli',
     'teşhis',
     'tesirsiz',
     'tesis',
     'tesislerinde',
     'teslim',
     'teslimat',
     'teyel',
     'tez',
     'tezat',
     'ticaret',
     'tiftik',
     'tıkandın',
     'tıkanmış',
     'tıkınma',
     'tıkırtı',
     'tıknaz',
     'tiksindirici',
     'tiksinmek',
     'tilki',
     'tılsımı',
     'tıngırtı',
     'tipide',
     'tırabzan',
     'tıraşsız',
     'tire',
     'tırmanış',
     'tırmık',
     'tırnak',
     'tırtıklı',
     'titiz',
     'titizlik',
     'titrek',
     'titreme',
     'titreyerek',
     'tiyatro',
     'tiyatrocu',
     'tiz',
     'toka',
     'tokalaşma',
     'tokası',
     'tokasının',
     'tokatlamak',
     'tökezleyerek',
     'tokmağı',
     'tombul',
     'tomurcuklanan',
     'tonlarca',
     'top',
     'topak',
     'topalak',
     'topallama',
     'toplama',
     'toplanmak',
     'toplanmış',
     'toplantı',
     'toplayıcı',
     'toplu',
     'toprak',
     'topraksı',
     'topraksız',
     'topuz',
     'torbalama',
     'torbalı',
     'törene',
     'törpüleme',
     'tortu',
     'torun',
     'tövbe',
     'toz',
     'tozluk',
     'trafik',
     'trajedi',
     'traktör',
     'trans',
     'trapez',
     'travesti',
     'tren',
     'tribün',
     'trilyon',
     'triseps',
     'triyaj',
     'trombon',
     'tropikal',
     'tropikler',
     'tüfek',
     'tugay',
     'tuğla',
     'tuğrası',
     'tuhaf',
     'tükenmeyen',
     'tüketen',
     'tüketici',
     'tüketilecek',
     'tüketme',
     'tüm',
     'tüplü',
     'tür',
     'türban',
     'türbe',
     'türbin',
     'türetilmiş',
     'türkiye',
     'türler',
     'turnusol',
     'turp',
     'turta',
     'turunçgiller',
     'tut',
     'tutarlı',
     'tutarlılık',
     'tutkal',
     'tutku',
     'tutsaklık',
     'tuttu',
     'tutuklamak',
     'tutukluk',
     'tutulma',
     'tutum',
     'tutumlu',
     'tutumluluk',
     'tutumsuz',
     'tutuşmak',
     'tuvalet',
     'tüvit',
     'tüy',
     'tüyü',
     'tuz',
     'tuzak',
     'tuzaklar',
     'tuzlu',
     'tuzsuz',
     'tüzük',
     'uçan',
     'üçgen',
     'üçleme',
     'uçlu',
     'üçlü',
     'üçlüsü',
     'ücretler',
     'ücretli',
     'ücretsiz',
     'uçucu',
     'uçulabilir',
     'uçurtma',
     'uçuş',
     'uçuşan',
     'ufak',
     'üfleme',
     'uğrak',
     'uğramak',
     'uğraş',
     'uğraşan',
     'uğraşma',
     'uğraşmak',
     'uğultu',
     'uğultusunu',
     'uğursuz',
     'uğursuzluk',
     'ulaşılmamış',
     'ulaşım',
     'ulaşmak',
     'ülke',
     'ültimatom',
     'ulusal',
     'umursamaz',
     'umutsuzluk',
     'üniformalı',
     'unsurların',
     'ünsüz',
     'unutkan',
     'uranyum',
     'üreme',
     'üremek',
     'üretan',
     'üretmek',
     'urgan',
     'ürkek',
     'ürolog',
     'üroloji',
     'ürpertici',
     'ürün',
     'uşak',
     'üst',
     'usta',
     'ustalıkla',
     'üstlendi',
     'üstün',
     'üstüne',
     'üstünlük',
     'utanç',
     'utangaç',
     'utanıyordu',
     'ütopya',
     'ütü',
     'uvertür',
     'üveyik',
     'uyan',
     'uyandı',
     'uyandırmak',
     'uyanma',
     'uyanmak',
     'uyaranlar',
     'uyarıcı',
     'uyarılabilir',
     'uyarılma',
     'uyarınca',
     'uydurma',
     'uydurması',
     'uygulamak',
     'uygulamalı',
     'uygulamayın',
     'uygun',
     'uygunsuz',
     'uygunsuzdur',
     'uyku',
     'uykuda',
     'uykulu',
     'uyluk',
     'uymak',
     'uymamaya',
     'uysal',
     'uysallıkla',
     'uyudu',
     'uyuklama',
     'uyuklayan',
     'uyum',
     'uyumlu',
     'uyuşma',
     'uyuşturuyor',
     'uyuşuk',
     'uyuşukluk',
     'uyutmak',
     'uyuz',
     'uzadıya',
     'uzak',
     'uzaklaşmış',
     'uzaklaştıran',
     'uzaklaştırıldı',
     'uzaktaki',
     'uzaktan',
     'uzanan',
     'uzanma',
     'uzantı',
     'uzatmak',
     'uzattı',
     'üzerine',
     'uzlaşma',
     'uzlaştırma',
     'uzman',
     'üzüm',
     'uzun',
     'uzunbacak',
     'uzunluk',
     'üzüntü',
     'uzuv',
     'vaaz',
     'vadi',
     'vagon',
     'vagonu',
     'vaha',
     'vahşet',
     'vahşi',
     'vahşilik',
     'vaiz',
     'vak',
     'vaki',
     'vals',
     'vanilya',
     'vardı',
     'varil',
     'varış',
     'varlık',
     'varlıklı',
     'varsayılan',
     'vasıfsız',
     'vasküler',
     'vatandaş',
     'vatansever',
     'vatoz',
     'vay',
     'vazelin',
     'vazgeçirmek',
     'vazgeçmek',
     'vefasız',
     'vekâlet',
     'vekil',
     'velayet',
     'ventrikül',
     'venüs',
     'veranda',
     'verdi',
     'veremli',
     'veren',
     'vergilendirilmemiş',
     'veri',
     'verici',
     'verimli',
     'verimsiz',
     'verir',
     'vernik',
     'versiyonu',
     'vinç',
     'virgül',
     'virüs',
     'viskoz',
     'viskozite',
     'vitaminler',
     'vitrin',
     'vize',
     'vızıltı',
     'vizyon',
     'vokalist',
     'voleybolu',
     'voltaj',
     'vücut',
     'vurdu',
     'vurgu',
     'vurgular',
     'vurgulu',
     'vurmak',
     'vurulmuş',
     'vuruş',
     'yabancı',
     'yabancılar',
     'yabancılaştırmak',
     'yabani',
     'yağ',
     'yağan',
     'yağcı',
     'yağlamak',
     'yağma',
     'yağmurlama',
     'yağsız',
     'yaka',
     'yakalayıcı',
     'yakın',
     'yakınlarda',
     'yakınlık',
     'yakışıksız',
     'yaklaşan',
     'yaklaşmak',
     'yakma',
     'yakut',
     'yalak',
     'yalaka',
     'yalama',
     'yalan',
     'yalancı',
     'yalanlamak',
     'yalıtılmış',
     'yalıtımı',
     'yalnizca',
     'yalnızca',
     'yalpa',
     'yalpalama',
     'yaltaklanmak',
     'yalvaran',
     'yamuk',
     'yanak',
     'yanan',
     'yanıcı',
     'yanık',
     'yanıklığının',
     'yanılmak',
     'yanılsama',
     'yanıltıcı',
     'yanında',
     'yankı',
     'yanlış',
     'yanmamış',
     'yansıtılır',
     'yapabilmek',
     'yapamaz',
     'yapboz',
     'yapı',
     'yapıcı',
     'yapılabilir',
     'yapılandır',
     'yapılmamış',
     'yapımı',
     'yapısı',
     'yapışık',
     'yapışkan',
     'yapışkanlık',
     'yapışma',
     'yapıştırılan',
     'yapıştırma',
     'yapıyor',
     'yapmacık',
     'yapmıyordum',
     'yara',
     'yaralanmamış',
     'yaralı',
     'yarar',
     'yararsız',
     'yarasalar',
     'yarasız',
     'yaratıcı',
     'yaratık',
     'yaratın',
     'yardakçısı',
     'yardımcı',
     'yardımcısı',
     'yargı',
     'yarık',
     'yarıları',
     'yarım',
     'yarış',
     'yarışma',
     'yarıya',
     'yarıyıl',
     'yasal',
     'yasama',
     'yaşamış',
     'yaşanabilir',
     'yaşasın',
     'yaşayan',
     'yasemin',
     'yaşlanma',
     'yaslanmak',
     'yaşlanmayan',
     'yaslayıcı',
     'yaslı',
     'yaşlı',
     'yaşlılar',
     'yastıklı',
     'yatan',
     'yatar',
     'yatırmak',
     'yatışmak',
     'yatıştırıcı',
     'yatıştırmak',
     'yatkın',
     'yavaş',
     'yavru',
     'yavrulamak',
     'yay',
     'yayarlar',
     'yaygaracılık',
     'yaygın',
     'yayıcı',
     'yayık',
     'yayılan',
     'yayılma',
     'yayılmış',
     'yayımcı',
     'yaylanma',
     'yaylı',
     'yazar',
     'yazarlarından',
     'yazdır',
     'yazı',
     'yazık',
     'yazılı',
     'yazılmamış',
     'yazım',
     'yediemin',
     'yediği',
     'yedinci',
     'yeğen',
     'yelek',
     'yelken',
     'yelpaze',
     'yem',
     'yemek',
     'yemin',
     'yeminsiz',
     'yemlik',
     'yeniden',
     'yenilebilir',
     'yenilemek',
     'yenilenebilir',
     'yenilenen',
     'yenilenme',
     'yenilgi',
     'yenilmedi',
     'yenilmiş',
     'yenmek',
     'yenmemiş',
     'yenmez',
     'yer',
     'yerçekimi',
     'yerinden',
     'yerine',
     'yerleşke',
     'yerleşme',
     'yerleşmek',
     'yerleşti',
     'yerleştirmek',
     'yerli',
     'yeşil',
     'yetenek',
     'yetenekli',
     'yetersiz',
     'yetiştiricisi',
     'yetiştirmek',
     'yetmiş',
     'yidce',
     'yığın',
     'yiğit',
     'yıkama',
     'yıkanabilir',
     'yıkandı',
     'yıkanmamış',
     'yıkayıcı',
     'yıkım',
     'yılan',
     'yılanbalığı',
     'yılda',
     'yıldırıcı',
     'yıldırım',
     'yıldırmak',
     'yıldız',
     'yıldızlı',
     'yıldızlık',
     'yıldızsız',
     'yıllık',
     'yılmaz',
     'yinelediler',
     'yıpranmalar',
     'yıpranmayı',
     'yıpranmış',
     'yıpranmışlık',
     'yirmi',
     'yirminci',
     'yırtıcı',
     'yiv',
     'yoğun',
     'yoğunlaşmasına',
     'yoğunluk',
     'yoğurt',
     'yoketme',
     'yokluğu',
     'yoksul',
     'yokuş',
     'yol',
     'yolcu',
     'yolculuk',
     'yoldaş',
     'yolluk',
     'yolmak',
     'yonca',
     'yönetici',
     'yönetilmeyen',
     'yönetmek',
     'yonga',
     'yönlendirilmiş',
     'yönlendirme',
     'yönlü',
     'yönü',
     'yorgan',
     'yorucu',
     'yorumu',
     'yosun',
     'yosunlu',
     'yo-yo',
     'yüce',
     'yüceltilmeleri',
     'yüceltmek',
     'yudum',
     'yudumlamak',
     'yuhalanmak',
     'yukarı',
     'yükleme',
     'yükseklik',
     'yükselen',
     'yükseliş',
     'yükselmek',
     'yükselmeye',
     'yükselmiş',
     'yükselt',
     'yükseltmek',
     'yüksük',
     'yüksüz',
     'yükümlülük',
     'yulaf',
     'yumruk',
     'yumurtalık',
     'yumurtlamak',
     'yumuşak',
     'yumuşakça',
     'yumuşat',
     'yün',
     'yunus',
     'yurt',
     'yürümek',
     'yürünen',
     'yusufçuk',
     'yutan',
     'yutmak',
     'yuva',
     'yuvarlakça',
     'yuvarlaklık',
     'yuvarlama',
     'yuvarlanan',
     'yüz',
     'yüzde',
     'yüzen',
     'yüzer',
     'yüzey',
     'yüzme',
     'yüzsüz',
     'yüzücü',
     'yüzüncü',
     'zaaf',
     'zafer',
     'zahmetli',
     'zalimce',
     'zamanlama',
     'zamansız',
     'zambak',
     'zanaatkarın',
     'zar',
     'zarar',
     'zararlı',
     'zararsız',
     'zarf',
     'zarif',
     'zavallı',
     'zayıf',
     'zayıflatmak',
     'zayıflık',
     'zehir',
     'zehirli',
     'zeki',
     'zekice',
     'zemin',
     'zengin',
     'zenginleştirmek',
     'zenginliği',
     'zenginlik',
     'zeplin',
     'zerre',
     'zevk',
     'zevkli',
     'zevklilik',
     'zeytin',
     'zikir',
     'zikzaklı',
     'zil',
     'zımbalama',
     'zımpara',
     'zincir',
     'zindelik',
     'zıp',
     'zıplama',
     'zıplayan',
     'zırhlı',
     'zırhsız',
     'ziyaret',
     'ziyaretçi',
     'zodyak',
     'zombi',
     'zonklama',
     'zoolog',
     'zooloji',
     'zor',
     'zorba',
     'zorlama',
     'zorlamak',
     'zorlanan',
     'zorluk',
     'zorlukla',
     'zorunlu',
     'zorunluluk',
     'zulmetmekte',
     'zümrüt',
     'züppe'

];