import React from 'react';
import styled from 'styled-components';

const Styles = styled.p`
  background: ${p => p.theme.colors.lightGray};
  background: white;
  padding: 2rem;
  font-size: 0.8em;
  border-radius: ${p => p.theme.borderRadius};
`;

export default function Disclaimer() {
  return (
    <Styles>
      Şifreleriniz burada kriptografik olarak güçlü bir rastgele sayı üretecisi tarafından üretilir ve herhangi bir şekilde paylaşılmaz. Sadece meraklı gözlere dikkat edin ve işiniz bittiğinde tarayıcıyı kapatın.
    </Styles>
  );
}
