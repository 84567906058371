import React from 'react';
import styled, { css } from 'styled-components';
import config from 'config';
import { media } from 'styles/helpers';
import InputRow from 'components/common/InputRow';
import CheckBox from 'components/common/CheckBox';
import RangeSlider from 'components/common/RangeSlider';

const Slider = styled(RangeSlider)`
  ${media.tablet`
    width: 70%;
  `}
`;

const Styles = styled.div`
  padding: 2rem;
  .checkboxes {
    > div {
      flex-basis: 50%;
      margin-right: 0;
    }
  }
  ${media.tablet`    
  .checkboxes {
    > div {
      flex-basis: auto;
      margin-right: 3rem;
    }
  }
  `}
  ${p =>
    p.isPwaMode &&
    css`
      padding: 1rem 2rem;
      & div:first-child {
        margin-top: 0;
      }
    `}
`;

export default function PasswordParams({ values, onChange, isPwaMode }) {
  const { modes } = config;
  const pwValues = values[modes.PW];
  return (
    <Styles isPwaMode={isPwaMode}>
      {!isPwaMode && (
        <p>
          İyi şifreler en az 12 karakter uzunluğundadır. Eğer yapabilirseniz, rastgele sırayla harfler, sayılar ve semboller ekleyin. Hesap sağlayıcınızın izin verdiği kadar uzun şifreler oluşturun, şifre yöneticisi aldıktan sonra zaten her seferinde yazmanıza gerek kalmayacak.
        </p>
      )}
      <InputRow>
        <Slider
          label="Uzunluk"
          name="length"
          value={pwValues.length}
          min={8}
          max={50}
          onChange={onChange}
          title="Adjust password length"
        />
      </InputRow>
      Karakter
      <InputRow className="checkboxes">
        <CheckBox
          label="abc"
          name="lower"
          checked={pwValues.lower}
          onChange={onChange}
          title="Include lowercase letter"
        />
        <CheckBox
          label="ABC"
          name="upper"
          checked={pwValues.upper}
          onChange={onChange}
          title="Include uppercase letter"
        />
        <CheckBox
          label="123"
          name="numbers"
          checked={pwValues.numbers}
          onChange={onChange}
          title="Include numbers"
        />
        <CheckBox
          label="$@!"
          name="symbols"
          checked={pwValues.symbols}
          onChange={onChange}
          title="Include symbols"
        />
      </InputRow>
    </Styles>
  );
}
