import React from 'react';
import styled from 'styled-components';
import config from 'config';
// import { ReactComponent as Heart } from 'images/heart.svg';
import { Link } from 'react-router-dom';

const StyledContainer = styled.div`
  padding: 3rem 0;
  text-align: center;
  font-size: 0.75em;
  color:white;
  .v {
    font-family: ${p => p.theme.fontFixed};
  }
  svg {
    height: 1em;
    color: crimson;
    margin: 0 0 -0.25rem 0;
  }
`;

const Footer = () => {
  return (
    <StyledContainer>
      <span style={{ color: "#A0CFD3"}}>v{config.version}</span>
      <br />
      <span style={{ color: "#A0CFD3", marginTop: ".75rem"  }}> Sifre.net,
      <Link to={{ pathname: "https://def24.com/tr" }} target="_blank" title="DEF24 Inc." className="nav-link pl-1">
        DEF24 Inc.
      </Link> tarafından geliştirilmiştir.
      </span>
      <br />{' '}
      <span style={{marginTop: "1rem"}}>
        <Link to="/" className="nav-link" > Anasayfa </Link>
        <Link to="/privacy-policy" className="nav-link" > Gizlilik Sözleşmesi  </Link> </span>
    </StyledContainer>
  );
};

export default Footer;
