import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  margin: 3rem 0;
  p,
  ul {
    font-size: 0.9em;
    color: white;
  }
  ul {
    padding: 0 0 0 2.5rem;
  }
  li {
    margin: 1rem 0 1.25rem;
    list-style: none;
    position: relative;
    line-height: 1.5;
    color: white;
  }
  li:last-child {
    margin: 1rem 0 0;
  }
  li:before {
    content: '*';
    position: absolute;
    left: -2.25rem;
    top: 0;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.2;
  }
  h2{
    color:white;
  }
`;

export default function About() {
  return (
    <Styles>
      <h2 id="about">İyi şifre kuralları</h2>
      <p>
      İstatistiklerin bir parçası olmayın! Güvenli şifreler, çevrimiçi tehditlere karşı korunmada ilk savunmanızdır.
      </p>
      <ul>
        <li> Köpeğinizin doğum günü gibi kişisel bilgileri şifre olarak kullanmayın.</li>
        <li>
             Kimlik bilgilerinizi mail veya kısa mesaj aracılığıyla paylaşmayın.  Bunlar üçüncü tarafların müdahalesine tamamen açıktır.
        </li>
        <li>
            Her hesap için farklı bir şifre kullanın.  Eğer biri 8 ay önce kullandığınız meme üretme sitesini hacklerse ve siz de bu burada kullandığınız şifreyi tüm hesaplarınızda kullanıyorsanız, başınız belada demektir. Ve şimdi 308 farklı hesapta şifre değiştirmeniz gerekiyor (Çok kötü bir durum). Evet, her hesap için benzersiz bir şifre belirlemek şifre yöneticisi olmadan neredeyse imkansız...
        </li>
        <li>
            Şifre yöneticisi kullanın. Birçoğu ücretsiz deneme süresi içeren birçok seçeneğiniz var. Tüm cihazlarınızla senkronize olan bir mobil uygulama alın ve bu şekilde şifrelerinize her zaman erişebileceksiniz. Şifre yöneticinizi güvence altına almak için vatan-bayrak-toprak-millet-asker gibi uzun ama hatırlanması kolay bir "master" anahtar parola kullanın ve bireysel hesaplarınızı uzun rastgele şifrelerle koruyun. Neyse ki, şimdi hemen hemen tüm tarayıcı ve işletim sistemlerinde dahili temel şifre yönetimi bulunmaktadır. Ve biyometrik erişim kontrollerinin artması ile (Face ID ve benzerleri) uzun karmaşık şifreler kullanmamak için gerçekten bir nedeniniz yok.
        </li>
        <li>
            Güvenlik sorularını rastgele şifreler veya anahtar parolalarla yanıtlayın ve bunları şifre yöneticinizde saklayın. Bu bilgilerin bazıları, örneğin annenizin kızlık soyadı gibi, muhtemelen başkaları tarafından bilinnmektedir. Çoğu şifre yöneticisinde şimdi bu özellik bulunmaktadır.
        </li>
        <li>
            "Master" şifrenizi periyodik olarak değiştirin. 5 kelimelik bir anahtar parolayı kalıcı olarak ezberlemek sadece birkaç dakika alır.
        </li>
      </ul>
    </Styles>
  );
}
