import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  margin: 3rem 0;
  ol {
    font-size: 0.9em;
  }
  h2,li {
    color: white;
  }
`;

export default function Instructions() {
  return (
    <Styles>
      <h2>Nasıl çalışır?</h2>
      <ol>
        <li>Parametrenizi belirleyin</li>
        <li>Oluşturulan herhangi bir şifreyi seçin, hepsi de işe yarayacaktır</li>
        <li>Kopyalayın</li>
        <li>Güvenliğinizin keyfini çıkarın</li>
      </ol>
    </Styles>
  );
}
